import { useMemo } from 'react';

import { mediaQueries } from 'global-constants';
import useMediaQuery from 'modules/hooks/useMediaQuery';
import PopupMenu, { ActionType, IPopupMenuProps } from 'components/PopupMenu';

const panelStyle = {
  zIndex: 1000,
  width: '500px',
  padding: '14px 0',
};

interface INavigationPopupProps extends IPopupMenuProps {
  actions: (ActionType & { isItemActive?: boolean })[];
}

export const NavigationPopup = (props: INavigationPopupProps) => {
  const isMobileLayout = useMediaQuery(mediaQueries.medium);

  const actions = useMemo(
    () =>
      props.actions.map(action => ({
        ...action,
        Wrapper:
          action.Wrapper &&
          (_props =>
            action.Wrapper ? (
              <action.Wrapper
                {..._props}
                isActive={action.isItemActive}
                onClick={() => {
                  action.onClick && action.onClick();
                  _props.closeMenu();
                }}
              />
            ) : null),
      })),
    [props.actions],
  );

  if (isMobileLayout) {
    return null;
  }

  return (
    <PopupMenu
      panelStyle={panelStyle}
      tooltipSettings={{ isShown: false }}
      {...props}
      actions={actions}
    />
  );
};
