import { useState, ChangeEvent } from 'react';

type TUseSearch = () => {
  searchValue: string;
  onSearchValueChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchValueClear: () => void;
};

const useSearch: TUseSearch = () => {
  const [searchValue, setSearchValue] = useState<string>('');

  const onSearchValueChange = e => setSearchValue(e.target.value);
  const onSearchValueClear = () => setSearchValue('');

  return {
    searchValue,
    onSearchValueChange,
    onSearchValueClear,
  };
};

export default useSearch;
