import api from 'services/api';
import { Snippet, SnippetTypeEnum } from './types';

export const getSnippetsAPI = (userId: number) => api.get(`/user/${userId}/snippets`);
export const deleteSnippetAPI = (userId: number, id: number) =>
  api.delete(`/user/${userId}/snippet/${id}`);

export const createSnippetAPI = (
  userId: number,
  snippetType: keyof typeof SnippetTypeEnum,
  values: Partial<Snippet>,
) => api.post(`/user/${userId}/snippet/${snippetType}`, values);

export const updateSnippetAPI = (userId: number, snippetId: number, values: Partial<Snippet>) =>
  api.patch(`/user/${userId}/snippet/${snippetId}`, values);
