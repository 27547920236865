import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectors as userSelectors } from 'modules/auth';
import { Roles, AllPermissionsType } from 'modules/auth/types';
import { checkPermissions, isAdmin } from 'modules/auth/utils';

export const useCheckPermissions = ({
  permissionAccess,
  roleAccess,
}: {
  permissionAccess?: AllPermissionsType[];
  roleAccess?: Roles[];
}) => {
  const user = useSelector(userSelectors.selectUser);

  const { hasCorrespondedPermission, hasCorrespondedRole } = useMemo(
    () => checkPermissions({ user, permissionAccess, roleAccess }),
    [user, permissionAccess, roleAccess],
  );

  if (permissionAccess?.length) {
    return hasCorrespondedPermission;
  }
  if (roleAccess?.length) {
    return hasCorrespondedRole;
  }

  return false;
};

export const useIsAdminUser = () => {
  const user = useSelector(userSelectors.selectUser);

  const isAdminUser = useMemo(() => isAdmin(user), [user]);

  return isAdminUser;
};
