import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { I18nEnum, UserRoutes } from 'types';
import { selectors as userSelectors } from 'modules/auth';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { actions as marketActions } from 'modules/markets';
import { queryClient } from 'modules/reactQuery';
import { UpsertOrderBody } from 'modules/orders/components/CreateOrder/types';
import { Roles } from 'modules/auth/types';
import { DeveloperTabKeys } from 'modules/developer/types';
import { services, MarketsAccounts, Formula, constants, BeaconOrder, queries } from '.';
import { useCheckPermissions } from 'modules/hooks/useCheckPermissions';

export const useAssignBeaconAccountsToMarkets = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: MarketsAccounts; isPartial: boolean; callback: () => void }>({
    mutationFn: variables => services.assignBeaconAccountsToMarketsAPI(user.id, variables.body),
    onSuccess: async (_, variables) => {
      if (variables.isPartial) {
        dispatch(
          modalActions.openModal(ModalTypes.successModal, {
            subtitle: I18nEnum.ChangesHaveBeenSavedSuccessfully,
            description: intl.formatMessage({
              id: I18nEnum.YouHaveNotAssociatedAllMarkets,
            }),
          }),
        );
      } else {
        dispatch(
          messageActions.openMessage(
            MessageTypes.success,
            intl.formatMessage({ id: I18nEnum.ChangesHaveBeenSavedSuccessfully }),
          ),
        );
      }

      await queries.invalidateOrdersQueries();

      variables.callback();
      dispatch(marketActions.getUserMarkets());
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
        ),
      );
    },
  });
};

export const useUpsertFormula = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: Formula; callback: () => void }>({
    mutationFn: variables => services.upsertFormulaAPI(user.id, variables.body),
    onSuccess: (_, variables) => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.ChangesHaveBeenSavedSuccessfully }),
        ),
      );
      queryClient.invalidateQueries({ queryKey: [constants.FORMULAS_KEY] });
      queryClient.invalidateQueries({ queryKey: [constants.ASSIGNMENT_MATCH_KEY] });
      variables.callback();
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
        ),
      );
    },
  });
};

export const useSaveDraftOrder = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<
    {
      body: UpsertOrderBody & {
        accountId: string;
      };
      callback: (orderId: number) => void;
      shouldClearCache: boolean;
    },
    { data: BeaconOrder }
  >({
    mutationFn: ({ body: { accountId, ...payload } }) =>
      services.saveDraftOrderApi(user.id, accountId, payload),
    onSuccess: async ({ data }, { callback, body, shouldClearCache }) => {
      shouldClearCache &&
        (await queryClient.invalidateQueries([
          constants.DASHBOARD_ORDER_FOR_STATISTICS_KEY,
          body.statisticsId,
        ]));
      callback(data.id);
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheOrderDraftHasNotBeenSaved }),
        ),
      );
    },
  });
};

export const useCreateOrder = ({ onSuccess }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelectors.selectUser);
  const hasAdminRights = useCheckPermissions({
    roleAccess: [Roles.companyAdmin, Roles.companyOwner, Roles.admin],
  });

  return useAppMutation<UpsertOrderBody & { accountId: string }, { data: BeaconOrder }>({
    mutationFn: ({ accountId, ...payload }) => services.createOrderApi(user.id, accountId, payload),
    onSuccess: (_, body) => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.YourOrderHasBeenPlaced }),
        ),
      );

      onSuccess();
      queryClient.invalidateQueries([
        constants.DASHBOARD_ORDER_FOR_STATISTICS_KEY,
        body.statisticsId,
      ]);
    },
    onError: error => {
      if (error.code === 401) {
        dispatch(
          modalActions.openModal(ModalTypes.warningModal, {
            subtitle: I18nEnum.BeaconProPlusIsDisconnected,
            subtitleValues: {
              isDisconnected: true,
            },
            description: intl.formatMessage({ id: I18nEnum.YourOrderHasNotBeenPlaced }),
            primaryBtnText: hasAdminRights ? I18nEnum.ReconnectAccount : I18nEnum.Ok,
            primaryAction: () => {
              if (hasAdminRights) {
                navigate(UserRoutes.developerInfo, {
                  state: { tab: DeveloperTabKeys.Integrations },
                });
              }
              dispatch(modalActions.closeModal());
            },
            hideSecondaryBtn: true,
          }),
        );
      }
    },
  });
};

export const useDeleteFormula = ({ onSuccess }: { onSuccess: () => void }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<number>({
    mutationFn: variables => services.deleteFormulaAPI(user.id, variables),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.FormulaHasBeenDeleted }),
        ),
      );
      dispatch(modalActions.closeModal());
      onSuccess();
      queryClient.invalidateQueries({ queryKey: [constants.FORMULAS_KEY] });
      queryClient.invalidateQueries({ queryKey: [constants.ASSIGNMENT_MATCH_KEY] });
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyFormulaHasNotBeenDeleted }),
        ),
      );
    },
  });
};

export const useDeleteDraftOrder = (statisticsId: number) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<number>({
    mutationFn: orderId => services.deleteDraftOrderAPI(user.id, orderId),
    onSuccess: () => {
      queryClient.invalidateQueries([constants.DASHBOARD_ORDER_FOR_STATISTICS_KEY, statisticsId]);
    },
  });
};
