import { useSelector } from 'react-redux';

import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { Company, HSCompany, constants, services, CompanySmtpServer } from 'modules/company';
import { selectors as userSelectors } from 'modules/auth';
import { User } from 'modules/auth/types';

export const useGetCompany = () => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<
    {
      company: Company;
      hsCompany: HSCompany;
    },
    Company
  >([constants.COMPANY_KEY, user.id], () => services.getCompanyAPI(user.id), {
    enabled: !!user.id,
    select: res => ({ ...res.company, hsCompany: res.hsCompany }),
  });
};

export const useIsNationalLicenseActive = () => {
  const { data: company } = useGetCompany();
  return company?.isNationalLicenseActive;
};

export const useGetPrimaryContact = () => {
  const { data: company } = useGetCompany();
  const hsCompany = company?.hsCompany;

  return hsCompany && hsCompany.properties.primary_contact_email
    ? {
        firstName: hsCompany.properties.primary_contact_first_name,
        lastName: hsCompany.properties.primary_contact_last_name,
        email: hsCompany.properties.primary_contact_email,
        phone: hsCompany.properties.primary_contact_phone,
        jobTitle: hsCompany.properties.primary_contact_job_title,
      }
    : undefined;
};

export const useGetUserOptions = () => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<User[]>([constants.USER_OPTIONS_KEY], () => services.getUserOptions(user.id), {
    enabled: !!user.id,
  });
};

export const useGetCompanySmtpServer = () => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<CompanySmtpServer | null>(
    [constants.COMPANY_SMTP_SERVER, user.id],
    () => services.getCompanySmtpServerAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};
