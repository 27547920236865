import { useMemo } from 'react';

import { AdminRoutes, I18nEnum, UserRoutes } from 'types';
import { useFilterNavItemsByPermissions } from './useFilterNavItemsByPermissions';
import { useIsActiveCheck } from './useIsActiveCheck';
import { MenuItem } from '../types';
import useOrdersNotification from 'modules/orders/hooks/useOrdersNotification';

const useSidebarMenu = (iconSrcPath = '/images/menu/') => {
  const checkIsActive = useIsActiveCheck();
  const filterNavItemsByPermissions = useFilterNavItemsByPermissions();

  const beaconNotification = useOrdersNotification();

  const fullMenu: MenuItem[] = useMemo(
    () => [
      {
        title: I18nEnum.Dashboard,
        iconSrc: `${iconSrcPath}dashboard-icon.svg`,
        link: UserRoutes.dashboard,
      },
      {
        title: I18nEnum.RepQuotes,
        iconSrc: `${iconSrcPath}rep-quotes.svg`,
        link: UserRoutes.repQuotes,
      },
      {
        title: I18nEnum.Financing,
        iconSrc: `${iconSrcPath}financing-icon.svg`,
        link: UserRoutes.financingSettings,
      },
      {
        title: I18nEnum.Orders,
        iconSrc: `${iconSrcPath}orders-icon.svg`,
        link: UserRoutes.orders,
        notification: beaconNotification,
      },
      {
        title: I18nEnum.Analytics,
        iconSrc: `${iconSrcPath}analytics-icon.svg`,
        link: UserRoutes.analyticsReports,
      },
      // Admin routes
      {
        title: I18nEnum.AdminDashboard,
        iconSrc: `${iconSrcPath}dashboard-icon.svg`,
        link: AdminRoutes.adminDashboard,
      },
    ],
    [iconSrcPath, beaconNotification],
  );

  const menu = useMemo(
    () => filterNavItemsByPermissions(fullMenu).map(checkIsActive),
    [fullMenu, filterNavItemsByPermissions, checkIsActive],
  );

  return menu;
};

export default useSidebarMenu;
