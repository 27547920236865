import { useRef, useEffect, useCallback } from 'react';

export default function useFallbackImage(fallbackSrc: string) {
  const ref = useRef<HTMLImageElement | null>(null);

  const onError = useCallback(
    e => {
      if (!fallbackSrc) {
        return;
      }
      e.target.src = fallbackSrc;
    },
    [fallbackSrc],
  );

  useEffect(() => {
    if (!fallbackSrc) {
      return;
    }

    if (ref && ref.current) {
      const { complete, naturalHeight } = ref.current;
      const errorLoadingImgBeforeHydration = complete && naturalHeight === 0;

      if (errorLoadingImgBeforeHydration) {
        ref.current.src = fallbackSrc;
      }
    }
  }, [fallbackSrc, ref]);

  return {
    ref,
    onError,
  };
}
