import { useCallback } from 'react';
import { Typography as TypographyBase } from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { colors, mediaQueries } from 'global-constants';
import { selectors, actions, MessageTypes } from 'modules/message';
import CloseIconBase from 'components/Icons/CloseIcon';
import WarningIconBase from 'components/Icons/WarningIcon';
import { I18nEnum } from 'types';

const Message = () => {
  const dispatch = useDispatch();
  const { opened, text, type } = useSelector(selectors.selectMessage);
  const close = useCallback(() => dispatch(actions.closeMessage()), [dispatch]);

  return opened ? (
    <Root type={type}>
      {typeof text === 'string' ? (
        <Row type={type} text={text} />
      ) : (
        text.map((item, ind) => <Row type={type} text={item} key={ind} />)
      )}
      <CloseIcon small onClick={close} id="message" />
    </Root>
  ) : null;
};

const Row = ({ type, text }: { type: string; text: string }) => (
  <RowRoot>
    {type === MessageTypes.success && (
      <div>
        <CheckCircleIcon
          src="/images/svg/check-circle-icon.svg"
          loading="lazy"
          height="24"
          width="24"
        />
      </div>
    )}
    {type === MessageTypes.error && <WarningIcon />}
    <Typography>
      {Object.keys(I18nEnum).includes(text) ? <FormattedMessage id={text} /> : text}
    </Typography>
  </RowRoot>
);

const Root = styled.div<{ type: string }>`
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1006;
  width: 455px;
  padding: 24px 43px 24px 24px;
  border-radius: 6px;
  background-color: ${props => (props.type === 'success' ? colors.green : colors.error)};
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);

  @media ${mediaQueries.small} {
    width: 280px;
    padding: 24px 48px 24px 24px;
  }
`;

const RowRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
`;

const Typography = styled(TypographyBase)`
  color: ${colors.white};
  opacity: 0.9;
  font-size: 16px;
  letter-spacing: 0.29px;
  line-height: 20px;
  white-space: pre-wrap;

  @media ${mediaQueries.small} {
    font-size: 14px;
    letter-spacing: 0.25px;
  }
`;

const CheckCircleIcon = styled.img`
  margin-right: 16px;
  min-height: 24px;
  min-width: 24px;
  filter: ${props => props.theme.colorFilters.white};
`;

const WarningIcon = styled(WarningIconBase)`
  margin-right: 16px;

  && svg {
    height: 24px;
    width: 26px;
  }
`;

const CloseIcon = styled(CloseIconBase)`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;

  #message {
    fill: ${colors.white};
  }
`;

export default Message;
