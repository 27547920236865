import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import keyBy from 'lodash/keyBy';

import { selectUser } from 'modules/auth/selectors';
import { checkPermissions } from 'modules/auth/utils';
import routes from 'modules/router/routes';
import { MenuItem } from '../types';

export const useFilterNavItemsByPermissions = () => {
  const user = useSelector(selectUser);

  const filterNavItemsByPermissions = useCallback(
    (navItems: MenuItem[]) => {
      const routesByPath = keyBy(routes, 'path');

      return navItems.filter(item => {
        const route = routesByPath[item.link];

        const { hasCorrespondedPermission, hasCorrespondedRole } = checkPermissions({
          user,
          permissionAccess: route?.permissionAccess,
          roleAccess: route?.roleAccess,
        });

        return hasCorrespondedPermission || hasCorrespondedRole;
      });
    },
    [user],
  );

  return filterNavItemsByPermissions;
};
