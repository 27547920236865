import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FloatingPortal } from '@floating-ui/react';

import { I18nEnum } from 'types';
import { mediaQueries } from 'global-constants';
import useMediaQuery from 'modules/hooks/useMediaQuery';
import { useOnClickOutside } from 'modules/hooks/useOnClickOutside';
import { MenuItem as MenuItemType, NotificationType } from './types';
import useSidebarMenu from './hooks/useSidebarMenu';
import { useSettingsMenu } from './hooks/useSettingsMenu';
import { useProfileMenu } from './hooks/useProfileMenu';
import { Notification } from './common-components';
import useMostSevereNotificationType from './hooks/useMostSevereNotificationType';

interface Props {
  signOutCallback: () => void;
}

export const MobileMenu: React.FC<Props> = ({ signOutCallback }) => {
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(false);
  const [openedCollapseItem, setOpenedCollapseItem] = useState<string | null>(null);

  const isMobileLayout = useMediaQuery(mediaQueries.medium);

  const onOutsideClick = useCallback(() => {
    setIsOpened(false);
  }, []);
  const ref = useOnClickOutside(onOutsideClick);

  const mainMenu = useSidebarMenu();
  const settingsMenu = useSettingsMenu();
  const profileMenu = useProfileMenu();

  const onMenuItemClick = (link: string) => {
    navigate(link);
    setIsOpened(false);
  };
  const toggleCollapse = (id: I18nEnum) => {
    setOpenedCollapseItem(openedCollapseItem === id ? null : id);
  };

  const mostSevereNotificationType = useMostSevereNotificationType([...mainMenu, ...settingsMenu]);

  if (!isMobileLayout) {
    return null;
  }

  return (
    <>
      <BurgerContainer>
        <BurgerIcon src="/images/svg/menu-icon.svg" onClick={() => setIsOpened(true)} />
        {mostSevereNotificationType && <Notification type={mostSevereNotificationType} />}
      </BurgerContainer>
      <FloatingPortal>
        <Root ref={ref} isOpened={isOpened}>
          <CloseIcon src="/images/svg/close-icon.svg" onClick={() => setIsOpened(false)} />
          {mainMenu.map(item => (
            <MenuItem onClick={() => onMenuItemClick(item.link)} isActive={item.isActive}>
              <NotificationContainer>
                <MenuItemIcon src={item.iconSrc} />
                {item.notification && <Notification type={item.notification.type} />}
              </NotificationContainer>
              <FormattedMessage id={item.title} />
            </MenuItem>
          ))}
          <Divider />
          <CollapseMenu
            items={settingsMenu}
            headerIcon="/images/menu/settings-icon.svg"
            headerTitle={I18nEnum.Settings}
            onMenuItemClick={onMenuItemClick}
            isOpened={openedCollapseItem === I18nEnum.Settings}
            onCollapse={() => toggleCollapse(I18nEnum.Settings)}
          />
          <CollapseMenu
            items={profileMenu}
            headerIcon="/images/menu/manage-accounts.svg"
            headerTitle={I18nEnum.Account}
            onMenuItemClick={onMenuItemClick}
            isOpened={openedCollapseItem === I18nEnum.Account}
            onCollapse={() => toggleCollapse(I18nEnum.Account)}
          />
          <Divider />

          <MenuItem onClick={signOutCallback}>
            <MenuItemIcon src="/images/svg/logout.svg" />
            <FormattedMessage id={I18nEnum.SignOut} />
          </MenuItem>
        </Root>
      </FloatingPortal>
    </>
  );
};

interface ICollapseMenuProps {
  items: MenuItemType[];
  headerIcon: string;
  headerTitle: I18nEnum;
  onMenuItemClick: (link: string) => void;
  isOpened: boolean;
  onCollapse: () => void;
}
const CollapseMenu = ({
  items,
  headerIcon,
  headerTitle,
  onMenuItemClick,
  isOpened,
  onCollapse,
}: ICollapseMenuProps) => {
  const mostSevereNotificationType = useMostSevereNotificationType(items);
  const hasActiveItem = useMemo(() => items.some(item => item.isActive), [items]);

  return items.length ? (
    <CollapseItem
      iconSrc={headerIcon}
      titleId={headerTitle}
      mostSevereNotificationType={mostSevereNotificationType}
      hasActiveItem={hasActiveItem}
      isOpened={isOpened}
      onCollapse={onCollapse}>
      {items.map(item => (
        <CollapseMenuItem onClick={() => onMenuItemClick(item.link)} isActive={item.isActive}>
          <NotificationContainer>
            <MenuItemIcon src={item.iconSrc} />
            {item.notification && <Notification type={item.notification.type} />}
          </NotificationContainer>
          <FormattedMessage id={item.title} />
        </CollapseMenuItem>
      ))}
    </CollapseItem>
  ) : null;
};

interface ICollapseItemProps {
  children: ReactNode;
  iconSrc: string;
  titleId: I18nEnum;
  mostSevereNotificationType?: NotificationType;
  hasActiveItem: boolean;
  onCollapse: () => void;
  isOpened: boolean;
}
const CollapseItem = ({
  children,
  iconSrc,
  titleId,
  mostSevereNotificationType,
  hasActiveItem,
  onCollapse,
  isOpened,
}: ICollapseItemProps) => {
  return (
    <CollapseItemRoot isOpened={isOpened}>
      <CollapseItemHeader onClick={onCollapse} isActive={hasActiveItem}>
        <NotificationContainer>
          <MenuItemIcon src={iconSrc} />
          {mostSevereNotificationType && <Notification type={mostSevereNotificationType} />}
        </NotificationContainer>
        <FormattedMessage id={titleId} />
        <CollapseArrowIcon src="/images/svg/arrow-icon.svg" />
      </CollapseItemHeader>
      <div>{children}</div>
    </CollapseItemRoot>
  );
};

const menuWidth = '280px';
const Root = styled.div<{ isOpened: boolean }>`
  padding-top: 32px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14);
  overflow-y: auto;
  position: fixed;
  width: ${menuWidth};
  height: 100vh;
  z-index: 1002;
  top: 0;
  right: ${({ isOpened }) => (isOpened ? 0 : `-${menuWidth}`)};
  border-radius: 0;
  transition: right 0.2s;
`;

const NotificationContainer = styled.div`
  position: relative;
`;

const BurgerContainer = styled(NotificationContainer)`
  display: flex;
  align-items: center;
  height: 20px;
  width: 22px;
`;

const BurgerIcon = styled.img`
  height: 15px;
  width: 20px;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 15px;
  right: 13px;
  opacity: 0.6;
`;

const MenuItem = styled.div<{ isActive?: boolean }>`
  padding: 0 32px;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 60px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 400;

  ${({ isActive, theme }) =>
    isActive &&
    `
      border-left: 4px solid ${theme.colors.blue};
      padding-left: 28px;     
  `}
`;

const MenuItemIcon = styled.img`
  width: 24px;
  height: 24px;
  filter: ${({ theme }) => theme.colorFilters.blue};
`;

const CollapseArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  opacity: 0.4;
  margin-left: auto;
  transform: rotate(180deg);
  transition: transform 0.2s;
`;

const CollapseItemRoot = styled.div<{ isOpened: boolean }>`
  overflow: hidden;
  height: 60px;

  ${({ isOpened }) =>
    isOpened &&
    `
    height: auto;
    
    ${CollapseArrowIcon} {
      transform: rotate(0);
    }
  `};
`;

const CollapseItemHeader = styled(MenuItem)``;

const CollapseMenuItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 72px;
  gap: 8px;

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.colors.blue};
    font-weight: 600;
  `}
`;

const Divider = styled.div`
  width: calc(100% - 32px);
  height: 1px;
  margin-left: 16px;
  background-color: ${({ theme }) => theme.colors.mediumGrey};
`;
