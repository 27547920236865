import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { RoutesTitles } from 'types';
import { useTrackPageViewAction } from 'modules/userAction';
import Tooltip from 'components/Tooltips/Tooltip';
import Head from 'components/Helmet/Helmet';
import useSidebarMenu from './hooks/useSidebarMenu';
import { Notification } from './common-components';

const Sidebar = () => {
  const navigate = useNavigate();
  useTrackPageViewAction();

  const menu = useSidebarMenu();

  const selectedKey = useMemo(() => menu.find(item => item.isActive)?.title, [menu]);

  const onSelect = useCallback(
    ({ key }) => {
      const selectedItem = menu.find(item => item.title === key);
      selectedItem && navigate(selectedItem.link);
    },
    [navigate, menu],
  );

  return (
    <Root>
      <Head title={RoutesTitles[location.pathname]} />
      <Menu selectedKeys={[selectedKey as string]} mode="inline" onSelect={onSelect}>
        {menu.map(item => (
          <MenuItem
            key={item.title}
            icon={
              <Tooltip
                tooltip={item.notification?.tooltip}
                isShown={!!item.notification}
                handleClose={null}>
                <IconWrapper>
                  <Icon active={!!item.isActive} src={item.iconSrc} />
                  {item.notification && <Notification type={item.notification.type} />}
                </IconWrapper>
              </Tooltip>
            }
            tabIndex={0}
            className="menu-item">
            <FormattedMessage id={item.title} />
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  z-index: 999;
  width: 84px;
  height: calc(100% - ${props => props.theme.elementsSizes.header.initial});
  background-color: #254c59;
  left: 0;
  top: ${props => props.theme.elementsSizes.header.initial};
  transition: width 0.3s;

  .ant-menu {
    background-color: transparent;
    height: 100%;
    padding: 32px 0;
    overflow: hidden auto;
    ::-webkit-scrollbar {
      width: 4px;
    }
  }

  .ant-menu-item {
    height: 60px;
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    letter-spacing: 0.33px;
    line-height: normal;
    gap: 6px 16px;
    flex-direction: column;
    justify-content: center;
    padding-left: 0 !important;
    padding-right: 0;

    & .ant-menu-title-content {
      flex: none !important;
    }

    span {
      font-size: 12px;
      margin: 0;
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      white-space: normal;
    }

    img {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba(9, 18, 21, 0.26);
      color: ${props => props.theme.colors.white};

      ${Notification} {
        background-color: rgba(30, 61, 71, 1);
      }
    }
  }

  && .ant-menu-item-selected {
    background-color: rgba(9, 18, 21, 0.26);

    span {
      color: ${props => props.theme.colors.white};
    }

    ${Notification} {
      background-color: rgba(30, 61, 71, 1);
    }
  }

  .ant-menu-item::after {
    left: 0;
    right: inherit;
    border-right: 4px solid #f9f8f7;
  }

  @media ${props => props.theme.mediaQueries.medium} {
    display: none;
  }

  &[data-whatinput='keyboard'] .menu-item:focus {
    border-radius: 3px;
    box-shadow: 0 0 0px 2px ${props => props.theme.colors.white};
    box-sizing: border-box;
  }
`;

const IconWrapper = styled.div`
  position: relative;

  ${Notification} {
    background-color: ${props => props.theme.colors.blue};
  }

  @media ${props => props.theme.mediaQueries.medium} {
    display: none;
  }
`;

const Icon = styled.img<{ active: boolean }>`
  height: 24px;
  width: 24px;
  filter: ${props => props.theme.colorFilters.white};
  ${props => !props.active && 'opacity: 0.7;'}
`;

const MenuItem = styled(Menu.Item)`
  &.ant-menu-item.menu-item {
    margin: 0 auto;
  }
`;

export default Sidebar;
