import { Icon, Props } from './Icon';

const WarningIcon = ({ id = '', ...props }: Props) => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 22">
      <defs />
      <defs>
        <path
          id="path-warning"
          d="M12.2 4.7L20.5 19H3.9l8.3-14.3zm0-4.4L.1 21.4h24.2L12.2.3zm1.1 15.4h-2.2V18h2.2v-2.3zm0-6.5h-2.2v4.4h2.2V9.2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-.2 .2)">
        <mask id={`mask-warning-${id}`} fill="#fff">
          <use xlinkHref="#path-warning" />
        </mask>
        <g id={id} fill="#FFF" mask={`url(#mask-warning-${id})`}>
          <path d="M.2-1.2h24v24H.2z" />
        </g>
      </g>
    </svg>
  </Icon>
);

export default WarningIcon;
