import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { I18nEnum } from 'types';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { MessageTypes, actions as messageActions } from 'modules/message';
import { actions as modalActions } from 'modules/modal';
import { services, actions as marketActions } from 'modules/markets';
import { selectors as userSelectors } from 'modules/auth';
import { invalidateMarketProductsQuery } from 'modules/product/queries';

export const useApplyMarketSettings = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ currentMarketSlug: string; selectedMarkets: string[] }>({
    mutationFn: async variables => {
      return services.applyMarketSettingsAPI(
        user.id,
        variables.currentMarketSlug,
        variables.selectedMarkets,
      );
    },
    onSuccess: () => {
      invalidateMarketProductsQuery();
      dispatch(marketActions.getUserMarkets());
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.ChangesHaveBeenApplied }),
        ),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChanges }),
        ),
      );
    },
  });
};
