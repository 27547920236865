import { useMemo } from 'react';

import { I18nEnum, UserRoutes } from 'types';
import { useFilterNavItemsByPermissions } from './useFilterNavItemsByPermissions';
import { useIsActiveCheck } from './useIsActiveCheck';

export const useProfileMenu = () => {
  const checkIsActive = useIsActiveCheck();
  const filterNavItemsByPermissions = useFilterNavItemsByPermissions();

  const menu = useMemo(
    () =>
      filterNavItemsByPermissions([
        {
          iconSrc: '/images/menu/profile-icon.svg',
          title: I18nEnum.MyProfile,
          link: UserRoutes.profile,
        },
        {
          iconSrc: '/images/menu/company-profile-icon.svg',
          title: I18nEnum.CompanyProfile,
          link: UserRoutes.companyProfile,
        },
        {
          iconSrc: '/images/menu/billing-icon.svg',
          title: I18nEnum.Billing,
          link: UserRoutes.billing,
        },
      ]).map(checkIsActive),
    [filterNavItemsByPermissions, checkIsActive],
  );

  return menu;
};
