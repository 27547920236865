export * from './hooks';
export * from './types';
export * from './constants';
export * from './services';

import { ActionLabelsMap } from './constants';

export const correctActionLabels = (label: string) => {
  if (label in ActionLabelsMap) {
    return ActionLabelsMap[label];
  }
  return label;
};
