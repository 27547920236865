import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { I18nEnum } from 'types';
import { selectors as userSelectors } from 'modules/auth';
import { useAppMutation } from 'modules/reactQuery';
import { actions as modalActions } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';

import { requestChangingSubscriptionStatusAPI } from './services';
import { SubscriptionRequestType } from './types';
import { invalidateSubscription } from './queries';

export const useRequestChangingSubscriptionStatus = (requestType: SubscriptionRequestType) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ subscriptionId: number; reasons?: string[] }>({
    mutationFn: async variables => {
      return requestChangingSubscriptionStatusAPI(user.id, variables.subscriptionId, {
        requestType,
        reasons: variables.reasons,
      });
    },
    onSuccess: () => {
      invalidateSubscription();
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.YourRequestHasBeenSent,
          }),
        ),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({
            id: I18nEnum.UnfortunatelyTheRequestHasNot,
          }),
        ),
      );
    },
  });
};
