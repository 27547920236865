import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { I18nEnum } from 'types';

const Head = ({ title, head }: { title?: string | I18nEnum; head?: JSX.Element }) => {
  const intl = useIntl();
  const isIntlTitle = useMemo(() => Object.keys(I18nEnum).includes(title || ''), [title]);

  const _title = useMemo(
    () =>
      isIntlTitle
        ? intl.formatMessage(
            { id: I18nEnum.headTitle },
            { pageTitle: intl.formatMessage({ id: title }) },
          )
        : title,
    [intl, isIntlTitle, title],
  );

  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{_title}</title>
      <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/favicon-64x64.png" sizes="64x64" />
      <link rel="manifest" href="/manifest.json" />
      {head}
    </Helmet>
  );
};

export default Head;
