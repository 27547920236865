import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { constants } from '..';

export const useClearProfile = () => {
  const queryClient = useQueryClient();

  const clearProfile = useCallback(() => {
    queryClient.removeQueries([constants.PROFILE_KEY]);
  }, [queryClient]);

  return clearProfile;
};
