import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { I18nEnum } from 'types';
import { selectors as marketSelectors, actions as marketActions } from 'modules/markets';
import { useGetBeaconConnection } from 'modules/developer/queries';
import { useHasAccountsManagementAccess } from 'modules/orders/hooks';
import { useIsAdminUser } from 'modules/hooks/useCheckPermissions';
import { STORAGE_HIDE_MARKETS_WARNING } from 'modules/orders/constants';
import { NotificationTypes } from 'components/Layout/navigation/types';

const useOrdersNotification = () => {
  const dispatch = useDispatch();
  const markets = useSelector(marketSelectors.selectMarkets);
  const { data: beaconConnection } = useGetBeaconConnection();

  const hasAccountsManagementAccess = useHasAccountsManagementAccess();
  const isAdminUser = useIsAdminUser();

  const [shouldCheckLS, setShouldCheckLS] = useState(false);

  const beaconNotification = useMemo(() => {
    if (!hasAccountsManagementAccess || !beaconConnection) {
      return;
    }
    if (!isAdminUser && localStorage.getItem(STORAGE_HIDE_MARKETS_WARNING) === 'true') {
      return;
    }
    if (beaconConnection && !beaconConnection.isConnected) {
      return {
        type: NotificationTypes.error,
        tooltip: <FormattedMessage id={I18nEnum.AccountIsDisconnectedCheckYourLoginAndPassword} />,
      };
    }
    const marketWithoutAccount = markets.some(market => !market.market.beaconAccountId);
    if (marketWithoutAccount) {
      return {
        type: NotificationTypes.warning,
        tooltip: <FormattedMessage id={I18nEnum.ToUnlockOrderingPleaseAssociateMarkets} />,
      };
    }
  }, [
    beaconConnection,
    markets,
    hasAccountsManagementAccess,
    isAdminUser,
    shouldCheckLS, // shouldCheckLS should be in dependencies to get new value by broadcast message
  ]);

  useEffect(() => {
    if (markets.length || !hasAccountsManagementAccess || !beaconConnection) {
      return;
    }
    dispatch(marketActions.getUserMarkets());
  }, [dispatch, markets, hasAccountsManagementAccess, beaconConnection]);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(STORAGE_HIDE_MARKETS_WARNING);
    broadcastChannel.onmessage = event => {
      setShouldCheckLS(!!event.data);
    };
  }, []);

  return beaconNotification;
};

export default useOrdersNotification;
