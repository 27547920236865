import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as globalActions from 'modules/global/actions';
import * as userSelectors from 'modules/auth/selectors';
import { SECONDS_INTERVAL } from 'modules/global/constants';
import { useClearProfile } from 'modules/profile';
import {
  queries as subscriptionQueries,
  actions as subscriptionActions,
} from 'modules/subscription';
import { actions as quickQuoteConfigActions } from 'modules/quickQuoteConfig';

let expirationInterval: number;

const InitRedux = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);
  const clearProfile = useClearProfile();
  const { data: subscriptionConfig } = subscriptionQueries.useGetSubscriptionConfig();

  useEffect(() => {
    dispatch(globalActions.getUser());
  }, [dispatch]);

  useEffect(() => {
    if (!subscriptionConfig) {
      return;
    }

    dispatch(subscriptionActions.setSubscriptionConfig(subscriptionConfig));
    dispatch(quickQuoteConfigActions.reset());
  }, [dispatch, subscriptionConfig]);

  useEffect(() => {
    if (!user || !Object.keys(user).length || !user.exp) {
      return;
    }

    clearInterval(expirationInterval);
    const { exp } = user;
    const expPopopDelay = SECONDS_INTERVAL.MAX * 1000; // {60} sec in ms
    const expirationTimeMs = exp * 1000; // exp time in ms

    if (Date.now() <= expirationTimeMs) {
      // run timer if exp time fewer than current time
      expirationInterval = window.setInterval(() => {
        const timeToShowExpPopup = Date.now() + expPopopDelay;
        if (timeToShowExpPopup >= expirationTimeMs) {
          import('modules/modal').then(({ ModalTypes, actions: modalActions }) =>
            dispatch(modalActions.openModal(ModalTypes.expirationModal, { maskClosable: false })),
          );
          clearInterval(expirationInterval);
        }
      }, 1000);

      return () => clearInterval(expirationInterval);
    }

    dispatch(globalActions.clearUser());
    clearProfile();
  }, [dispatch, user, clearProfile]);

  return null;
};

export default InitRedux;
