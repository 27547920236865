import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import styled, { css } from 'styled-components';

import { _SelectComponent as SelectBase } from 'components/Inputs/Select';
import ArrowDropdownIconBase from 'components/Icons/ArrowDropdownIcon';
import { getContractorsForDropdownAPI, getContractorToken } from 'modules/admin/services';
import { ContractorsDropdownOption } from 'modules/admin';
import { getUserFromTokenPayload } from 'modules/auth/utils';
import { setUser } from 'modules/auth/actions';
import { setToken } from 'modules/auth';
import { getAdminId, getAdminToken, removeAdminToken, setAdminToken } from 'modules/auth/storage';
import * as userSelectors from 'modules/auth/selectors';
import { AdminRoutes, UserRoutes, I18nEnum } from 'types';
import { queries as companyQueries } from 'modules/company';
import { subscriptionTypeToTranslationMap } from 'modules/subscription/constants';

const SelectContractor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(userSelectors.selectUser);
  const { data: company } = companyQueries.useGetCompany();

  const [dropdownOptions, setDropdownOptions] = useState<ContractorsDropdownOption[]>([]);
  const [userType, setUserType] = useState<'admin' | 'contractor'>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const adminToken = getAdminToken();

    if (adminToken) {
      setUserType('contractor');
    } else {
      setUserType('admin');
    }
  }, [dispatch, user.id]);

  const getInitialContractors = useCallback(async () => {
    const { data: _dropdownOptions } = await getContractorsForDropdownAPI(user.id, {
      search: '',
    });

    setDropdownOptions(_dropdownOptions);
  }, [user.id]);

  useEffect(() => {
    getInitialContractors();
  }, [getInitialContractors]);

  const searchContractors = useCallback(
    debounce(async search => {
      const { data: _dropdownOptions } = await getContractorsForDropdownAPI(user.id, {
        search,
      });

      setDropdownOptions(_dropdownOptions);
    }, 700),
    [user.id],
  );

  const handleAuth = useCallback(
    async _contractorId => {
      const adminToken = getAdminToken();

      if (_contractorId !== 'admin') {
        const adminId = getAdminId();
        if (adminToken) {
          setToken(adminToken);
        }

        const {
          data: { token },
          token: _adminToken,
        } = await getContractorToken(Number(adminId) || user.id, _contractorId);

        const userState = getUserFromTokenPayload(token);

        dispatch(setUser(userState));
        setToken(token);
        setAdminToken(_adminToken);
        window.location.assign(UserRoutes.products);
      } else if (adminToken) {
        const userState = getUserFromTokenPayload(adminToken);
        setToken(adminToken);
        dispatch(setUser(userState));
        removeAdminToken();
        setUserType('admin');
        setOpen(false);
        navigate(AdminRoutes.adminDashboard);
      } else {
        setOpen(false);
      }
    },
    [dispatch, user.id, navigate],
  );

  const handleSearchContractor = useCallback(
    value => {
      searchContractors.cancel();
      searchContractors(value);
    },
    [searchContractors],
  );

  const options = useMemo(() => {
    let _options: { value: string | number; label: string | JSX.Element }[] = dropdownOptions.map(
      contractor => {
        const subscriptionTypeTranslationId =
          subscriptionTypeToTranslationMap[
            `${contractor.subscriptionType}_${contractor.subscriptionCountry}`
          ] || '';
        return {
          label: contractor.companyName,
          children: (
            <Description>
              {subscriptionTypeTranslationId && (
                <DescriptionRow>
                  <FormattedMessage id={subscriptionTypeTranslationId} />
                </DescriptionRow>
              )}
              <DescriptionRow>{contractor.companyWebsite}</DescriptionRow>
            </Description>
          ),
          value: contractor.userId,
        };
      },
    );

    if (userType === 'contractor') {
      const selectedCompanySubscriptionTypeTranslationId =
        company &&
        company.subscriptions[0] &&
        subscriptionTypeToTranslationMap[
          `${company.subscriptions[0].type}_${company.subscriptions[0].country}`
        ];

      const userOption = {
        label: company?.name || 'Loading...',
        children: (
          <Description>
            {selectedCompanySubscriptionTypeTranslationId && (
              <DescriptionRow>
                <FormattedMessage id={selectedCompanySubscriptionTypeTranslationId} />
              </DescriptionRow>
            )}
            <DescriptionRow>{company?.website}</DescriptionRow>
          </Description>
        ),
        value: user.id,
      };

      _options = [userOption, ..._options.filter(option => option.value !== userOption.value)];
    }

    return _options;
  }, [dropdownOptions, user.id, userType, company]);

  const roofleAdminText = useMemo(
    () => intl.formatMessage({ id: I18nEnum.RoofleAdmin }).toUpperCase(),
    [intl],
  );

  const isAdminSelected = userType === 'admin';

  const dropdownRender = useCallback(
    menu => {
      const handleAdminClick = () => handleAuth('admin');

      return (
        <DropdownContainer>
          <RoofleAdmin
            onClick={handleAdminClick}
            className="ant-select-item ant-select-item-option"
            isAdminSelected={isAdminSelected}>
            {roofleAdminText}
          </RoofleAdmin>
          {menu}
        </DropdownContainer>
      );
    },
    [roofleAdminText, handleAuth, isAdminSelected],
  );

  return (
    <Select
      onChange={handleAuth}
      onSearch={handleSearchContractor}
      options={options}
      value={isAdminSelected ? roofleAdminText : user.id}
      showSearch
      dropdownRender={dropdownRender}
      suffixIcon={<ArrowDropdownIcon id="contractor-select" open={open} />}
      onDropdownVisibleChange={setOpen}
      open={open}
      filterOption={false}
    />
  );
};

const Select = styled(SelectBase)`
  height: 42px;
  border-radius: 6px;
  width: 248px;
  border: 1px solid ${({ open, theme }) => (open ? theme.colors.orange : `rgba(0, 0, 0, 0.4)`)};

  &:hover {
    border-color: ${props => props.theme.colors.orange};
  }

  .ant-select-focused.ant-select.ant-select-single .ant-select-selector {
    box-shadow: none;
  }

  &&:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border: none;
    border-radius: 6px;
    padding-right: 36px;
  }

  @media ${props => props.theme.mediaQueries.small} {
    display: none;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.black} !important;
    opacity: 0.9;
  }

  .ant-select-open .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    right: 27px;
  }

  .ant-select-selection-search-input {
    color: ${props => props.theme.colors.black} !important;
    opacity: 0.9;
    padding-right: 18px !important;
  }

  .ant-select-item {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: ${props => props.theme.colors.blue};
    height: 61px;
  }

  .ant-select-item-option-selected:before {
    content: '';
    width: 4px;
    height: 100%;
    background-color: ${props => props.theme.colors.blue};
    position: absolute;
    left: 0;
  }
`;

const DropdownContainer = styled.div`
  .ant-select-item-option-content {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Description = styled.div`
  max-width: 200px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.grey};
`;

const DescriptionRow = styled.div`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 4px;
`;

const ArrowDropdownIcon = styled(ArrowDropdownIconBase)<{ open: boolean }>`
  #arrow-dropdown-contractor-select {
    fill: ${props => props.theme.colors.black};
  }
  position: absolute;
  transform: translateY(-50%);
  opacity: ${({ open }) => (open ? 0.6 : 0.4)} !important;
  top: 50%;
  right: 17px;
  width: 10px;
  height: 5px;
  cursor: pointer;
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};

  ${({ open }) => open && `transform: translateY(-50%) rotate(180deg);`}
`;

const RoofleAdmin = styled.div<{ isAdminSelected: boolean }>`
  &.ant-select-item {
    height: 43px;
  }
  margin: 6px 0 7px;
  &:hover {
    background-color: #f5f5f5;
  }
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 1px;
    width: calc(100% - 16px);
    position: absolute;
    left: 8px;
    bottom: -7px;
    background: ${props => props.theme.colors.mediumGrey};
  }

  ${props =>
    props.isAdminSelected &&
    css`
      &:before {
        content: '';
        width: 4px;
        height: 100%;
        background-color: ${props => props.theme.colors.blue};
        position: absolute;
        left: 0;
      }
    `}
`;

export default SelectContractor;
