import { useSelector } from 'react-redux';

import { selectUser } from 'modules/auth/selectors';
import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { LeapIntegrationType } from '../types';
import { LEAP_CONNECTION_KEY } from '../constants';
import { services } from '..';

export const useGetLeapIntegration = () => {
  const user = useSelector(selectUser);

  const { data: leapIntegration, isFetching } = useAppQuery<LeapIntegrationType>(
    [LEAP_CONNECTION_KEY],
    () => services.getCompanyLeapIntegrationAPI(user.id),
    {
      enabled: !!user.id,
    },
  );

  return !isFetching ? leapIntegration : undefined;
};
