import { useMemo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { I18nEnum } from 'types';
import useIsAllMarketsEmailsConnected from './useIsAllMarketsEmailsConnected';
import { NotificationTypes, NotificationType } from 'components/Layout/navigation/types';
import { STORAGE_HIDE_EMAILS_WARNING } from '../constants';
import { useGetCompany } from 'modules/company/queries';
import { useIsAdminUser } from 'modules/hooks/useCheckPermissions';

const useEmailNotification = () => {
  const { isAllMarketsEmailsConnected, isSomeEmailDisconnected } = useIsAllMarketsEmailsConnected();
  const isAdminUser = useIsAdminUser();
  const { data: company } = useGetCompany();

  const isCompanyPaused = company?.isPaused && !isAdminUser;

  const [shouldCheckLS, setShouldCheckLS] = useState(false);

  const notification = useMemo(() => {
    if ((isAllMarketsEmailsConnected && !isSomeEmailDisconnected) || isCompanyPaused) {
      return;
    }
    if (localStorage.getItem(STORAGE_HIDE_EMAILS_WARNING) === 'true') {
      return;
    }

    const tooltipSetting: { type: NotificationType; message: I18nEnum } = {
      type: NotificationTypes.warning,
      message: I18nEnum.ToUseYourCompanyEmail,
    };
    if (isSomeEmailDisconnected) {
      tooltipSetting.type = NotificationTypes.error;
      tooltipSetting.message = I18nEnum.ConnectionFailedPleaseReconnect;
    }

    return {
      type: tooltipSetting.type,
      tooltip: (
        <FormattedMessage
          id={tooltipSetting.message}
          values={{
            a: chunks => <>{chunks}</>,
          }}
        />
      ),
    };
  }, [
    isAllMarketsEmailsConnected,
    isSomeEmailDisconnected,
    isCompanyPaused,
    shouldCheckLS, //  shouldCheckLS should be in dependencies to get new value by broadcast message
  ]);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(STORAGE_HIDE_EMAILS_WARNING);
    broadcastChannel.onmessage = event => {
      setShouldCheckLS(!!event.data);
    };
  }, []);

  return notification;
};

export default useEmailNotification;
