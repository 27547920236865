import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { I18nEnum } from 'types';
import { selectors as userSelectors } from 'modules/auth';
import { actions as modalActions } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { deleteEagleviewConnectionAPI } from '../services';
import { EAGLE_VIEW_CONNECTION_KEY } from '../constants';

export const useDisconnectEagleviewMutation = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<number[]>({
    mutationFn: ids => deleteEagleviewConnectionAPI(user.id, ids),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.EagleViewWasDisconnectedSuccessfully }),
        ),
      );
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [EAGLE_VIEW_CONNECTION_KEY],
        exact: true,
      });
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheEagleViewWasNotDisconnected }),
        ),
      );
    },
  });
};
