import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RoutesTitles } from 'types';
import * as userSelectors from 'modules/auth/selectors';
import { trackUserAction as _trackUserAction } from './services';
import { UserAction, EventAction, AuthActionLabel, TrackingConfig } from './types';
import { correctActionLabels } from '.';

export const useTrackUserAction = () => {
  const user = useSelector(userSelectors.selectUser);

  const trackUserAction = (userAction: UserAction, config?: TrackingConfig) => {
    if (!(user && user.id)) {
      return;
    }
    userAction.label = correctActionLabels(userAction.label);
    _trackUserAction(user.id, userAction, config);
  };

  return trackUserAction;
};

export const useTrackTabViewAction = () => {
  const trackUserAction = useTrackUserAction();

  const trackTabViewAction = (tabName: string, meta?: Record<string, unknown>) =>
    trackUserAction({
      action: EventAction.tabView,
      label: tabName,
      meta,
    });

  return trackTabViewAction;
};

export const useTrackAuthChangeAction = () => {
  const trackAuthChangeAction = async (userId, actionLabel: AuthActionLabel) => {
    await _trackUserAction(userId, {
      action: EventAction.authChange,
      label: actionLabel,
    });
  };

  return trackAuthChangeAction;
};

export const useTrackPageViewAction = () => {
  const location = useLocation();
  const prevTrackedPage = useRef<string>();
  const trackUserAction = useTrackUserAction();

  useEffect(() => {
    const label = RoutesTitles[location.pathname];
    if (label && prevTrackedPage.current !== label) {
      trackUserAction(
        {
          action: EventAction.pageView,
          label,
        },
        { ignorePageTracking: true },
      );
      prevTrackedPage.current = label;
    }
  }, [location.pathname, trackUserAction]);

  return null;
};

export const useTrackMoreActionsItemClickAction = () => {
  const trackUserAction = useTrackUserAction();

  const trackMoreActionsItemClickAction = (actionItem: string, meta?: Record<string, unknown>) =>
    trackUserAction({
      action: EventAction.moreActionsItemClick,
      label: actionItem,
      meta,
    });

  return trackMoreActionsItemClickAction;
};

export const useTrackButtonClickAction = () => {
  const trackUserAction = useTrackUserAction();

  const trackButtonClickAction = (
    buttonName: string,
    placeName = '',
    meta?: Record<string, unknown>,
  ) =>
    trackUserAction({
      action: EventAction.buttonClick,
      label: placeName ? `${placeName} - ${buttonName}` : buttonName,
      meta,
    });

  return trackButtonClickAction;
};
