import { useSelector } from 'react-redux';

import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import {
  ProfileState,
  constants as profileConstants,
  services as profileServices,
} from 'modules/profile';
import { selectors as userSelectors } from 'modules/auth';
import { formatPhone } from 'utils';

export const useGetProfile = () => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<{ user: ProfileState }, ProfileState>(
    [profileConstants.PROFILE_KEY, user.id],
    ({ queryKey }) => {
      const [_, userId] = queryKey;
      return profileServices.getUserApi(userId as number);
    },
    {
      enabled: !!user.id,
      select: profile => ({
        ...profile.user,
        phone: profile.user.phone ? formatPhone(profile.user.phone) : '',
      }),
    },
  );
};
