import { useSelector } from 'react-redux';

import { ListQuery } from 'types';
import { useAppQuery, queryClient } from 'modules/reactQuery';
import {
  constants,
  services,
  Account,
  Template,
  OrdersQuery,
  FilterOptions,
  Order,
  OrderDetails,
  TemplateItem,
  Job,
  Formula,
  FormulaQuery,
  AssignmentMatchBody,
  TaxRates,
  utils,
  BeaconProductsList,
  BeaconProductsRequestParams,
  BeaconProductVariations,
} from 'modules/orders';
import { selectors as userSelectors } from 'modules/auth';

export const invalidateOrdersQueries = () =>
  queryClient.invalidateQueries({
    predicate: query =>
      query.queryKey.some(_queryKey => constants.ordersQueriesKeys.includes(_queryKey as string)),
  });

export const useGetAccounts = (select?: (accounts: Account[]) => Account[]) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Account[]>(
    [constants.ACCOUNTS_KEY],
    () => {
      return services.getAccountsAPI(user.id);
    },
    {
      enabled: !!user.id,
      select,
    },
  );
};

export const useGetAccountById = (id: string) => {
  const select = (accounts: Account[]) => accounts.filter(item => item.accountLegacyId === id);
  return useGetAccounts(select);
};

export const useGetTemplates = (accountId = '', query?: ListQuery) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Template[]>(
    [constants.TEMPLATES_KEY, accountId, query],
    ({ queryKey }) => {
      const [_, _accountId, params] = queryKey;
      return services.getTemplatesAPI(
        user.id,
        _accountId as string,
        params as ListQuery | undefined,
      );
    },
    {
      enabled: !!(user.id && accountId),
    },
  );
};

export const useGetTemplateDetails = (accountId = '', templateId = '', jobNumber = '') => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Template>(
    [constants.TEMPLATE_KEY, accountId, templateId, jobNumber],
    ({ queryKey }) => {
      const [_, _accountId, _templateId, _jobNumber] = queryKey;

      return services.getTemplateDetailsAPI(
        user.id,
        _accountId as string,
        _templateId as string,
        _jobNumber as string,
      );
    },
    {
      enabled: !!(user.id && accountId && templateId),
      staleTime: Infinity,
    },
  );
};

export const useOrdersHistory = (params: OrdersQuery) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<{ ordersHistory: Order[]; total: number; scores: FilterOptions }>(
    [constants.ORDERS_HISTORY_KEY, params],
    ({ queryKey }) => {
      const [_, _params] = queryKey;
      return services.getOrdersHistoryAPI(user.id, _params as OrdersQuery);
    },
    {
      enabled: !!user.id,
    },
  );
};

export const useGetOrderDetails = (accountId?: string, orderId?: string) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<OrderDetails | null>(
    [constants.ORDER_DETAILS_KEY, accountId, orderId],
    ({ queryKey }) => {
      const [_, _accountId, _orderId] = queryKey;
      return services.getOrderDetailsAPI(user.id, _accountId as string, _orderId as string);
    },
    {
      enabled: !!(user.id && accountId && orderId),
    },
  );
};

export const useGetTemplatesItems = (accountId = '', templateIds: string[]) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<TemplateItem[]>(
    [constants.TEMPLATES_ITEMS_KEY, accountId, templateIds],
    ({ queryKey }) => {
      const [_, _accountId, _templateIds] = queryKey;
      return services.getTemplatesItemsAPI(user.id, _accountId as string, _templateIds as string[]);
    },
    {
      enabled: !!(user.id && accountId && templateIds.length),
    },
  );
};

export const useJobs = (accountId = '') => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Job[]>(
    [constants.JOBS_KEY],
    () => {
      return services.getJobsAPI(user.id, accountId);
    },
    {
      enabled: !!(user.id && accountId),
    },
  );
};

export const useGetFormulas = (query: FormulaQuery) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<{ formulas: Formula[]; total: number }>(
    [constants.FORMULAS_KEY, query],
    ({ queryKey }) => {
      const [_, params] = queryKey;
      return services.getFormulasAPI(user.id, params as ListQuery);
    },
    {
      enabled: !!(user.id && (!query.templateIds || query.templateIds.length)),
      select: data => ({
        formulas: utils.restoreDeletedMeasurementsFields(data.formulas),
        total: data.total,
      }),
    },
  );
};

export const useFindAssignmentMatch = (body: AssignmentMatchBody) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Formula | null>(
    [constants.ASSIGNMENT_MATCH_KEY, body],
    ({ queryKey }) => {
      const [_, _body] = queryKey;
      return services.findAssignmentMatchAPI(user.id, _body as AssignmentMatchBody);
    },
    {
      enabled: !!(user.id && body && body.productId),
    },
  );
};

export const useGetOrderTaxRates = (state?: string, zipcode?: string) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<TaxRates>(
    [constants.TAX_RATES, state, zipcode],
    ({ queryKey }) => {
      const [_, _state, _zipcode] = queryKey;
      return services.getOrderTaxRatesAPI(user.id, _state as string, _zipcode as string);
    },
    {
      enabled: !!(user.id && state && zipcode),
    },
  );
};

export const useGetProducts = (params: BeaconProductsRequestParams) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<BeaconProductsList>(
    [constants.PRODUCT_LIST, params],
    ({ queryKey }) => {
      const [_, _params] = queryKey;

      return services.getProducts(user.id, _params as BeaconProductsRequestParams);
    },
    {
      enabled: !!user.id,
    },
  );
};

export const getProductVariationsQueryFn = ({ queryKey }, userId) => {
  const [_, _productId] = queryKey;

  return services.getProductVariations(userId, _productId as string);
};
export const useGetProductVariations = (productId?: string) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<{ data: BeaconProductVariations }>(
    [constants.PRODUCT_VARIATIONS, productId],
    options => getProductVariationsQueryFn(options, user.id),
    {
      enabled: !!(user.id && productId),
    },
  );
};
