import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { I18nEnum } from 'types';
import { selectors as userSelectors } from 'modules/auth';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { services, constants, types } from '..';
import {
  MarketsAccounts,
  services as ordersServices,
  queries as orderQueries,
} from 'modules/orders';
import { actions as marketActions } from 'modules/markets';

export const useUpsertBeaconConnection = (isUpdate?: boolean) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: types.Creds; callback?: () => void }>({
    mutationFn: variables => services.upsertBeaconConnectionAPI(user.id, variables.body),
    onSuccess: async (_, variables) => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: isUpdate
              ? I18nEnum.ChangesHaveBeenSavedSuccessfully
              : I18nEnum.BeaconWasConnectedSuccessfully,
          }),
        ),
      );
      if (isUpdate) {
        dispatch(modalActions.closeModal());
      }

      queryClient.invalidateQueries({
        queryKey: [constants.BEACON_CONNECTION_KEY],
        exact: true,
      });
      await orderQueries.invalidateOrdersQueries();
      variables.callback && variables.callback();
    },
    onError: () => {
      if (isUpdate) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.error,
            intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
          ),
        );
      }
    },
  });
};

export const useDeleteBeaconConnection = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation({
    mutationFn: () => services.deleteBeaconConnectionAPI(user.id),
    onSuccess: async () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.BeaconProPlusHasBeenDisconnected }),
        ),
      );
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [constants.BEACON_CONNECTION_KEY],
        exact: true,
      });
      await orderQueries.invalidateOrdersQueries();
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyBeaconProPlusHasNotBeenDisconnected }),
        ),
      );
    },
  });
};

export const useAssignBeaconAccountsToMarkets = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{
    body: MarketsAccounts;
    marketsCount: number;
    assignedMarketsCount: number;
  }>({
    mutationFn: variables =>
      ordersServices.assignBeaconAccountsToMarketsAPI(user.id, variables.body),
    onSuccess: (_, variables) => {
      dispatch(
        modalActions.openModal(ModalTypes.beaconConnectionSuccess, {
          ...variables,
          fromScratch: true,
        }),
      );
      dispatch(marketActions.getUserMarkets());
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
        ),
      );
    },
  });
};
