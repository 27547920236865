import { toFixed } from 'utils';
import {
  measurementsInitialValues,
  MEASUREMENTS_FORM_FIELDS,
  measurementsDependingFields,
  deletedMeasurementsFormFieldsBackup,
} from 'modules/orders/constants';
import {
  Formula,
  MeasurementInputValues,
  Assignment,
  TemplateItemDetails,
  Variations,
} from 'modules/orders/types';
import { TemplateProduct } from './components/CreateOrder/types';

const getPrecedence = (character: string): number => {
  switch (character) {
    case '/':
    case '*':
      return 2;
    case '+':
    case '-':
      return 1;
    default:
      return -1;
  }
};

export const transformInfixToPostfix = (infix: (string | number)[]): (string | number)[] => {
  const stack: string[] = [];
  const result: (string | number)[] = [];

  infix.forEach(item => {
    if (typeof item === 'number' || /[a-zA-Z0-9]/.test(item)) {
      // If scanned formula part is an operand, add it to output.
      result.push(item);
    } else if (item == '(') {
      // If scanned formula part is an ‘(‘, push it to the stack.
      stack.push('(');
    } else if (item == ')') {
      // If scanned formula part is an ‘)’, pop and add to output from the stack until an ‘(‘ is encountered.
      while (stack[stack.length - 1] != '(') {
        result.push(stack[stack.length - 1]);
        stack.pop();
        if (!stack.length) {
          throw new Error('Invalid formula');
        }
      }
      stack.pop();
    } else {
      // If scanned formula part is an operator
      while (stack.length != 0 && getPrecedence(item) <= getPrecedence(stack[stack.length - 1])) {
        result.push(stack[stack.length - 1]);
        stack.pop();
      }
      stack.push(item);
    }
  });

  // Pop all the remaining elements from the stack
  while (stack.length != 0) {
    result.push(stack[stack.length - 1]);
    stack.pop();
  }

  return result;
};

export const calculateUsingPolishNotation = (expression: (string | number)[]): number => {
  const stack: number[] = [];
  if (!expression.length) {
    return 0;
  }

  for (let i = 0; i < expression.length; i++) {
    if (!isNaN(+expression[i]) && isFinite(+expression[i])) {
      stack.push(+expression[i]);
    } else {
      const a = stack.pop();
      const b = stack.pop();
      if (a === undefined || b === undefined) {
        throw new Error('Invalid formula');
      }

      switch (expression[i]) {
        case '+':
          stack.push(a + b);
          break;
        case '-':
          stack.push(b - a);
          break;
        case '*':
          stack.push(a * b);
          break;
        case '/':
          stack.push(b / a);
          break;
      }
    }
  }

  if (stack.length > 1) {
    throw new Error('Invalid formula');
  } else {
    return stack[0];
  }
};

export const transformMeasurementValuesForCalculations = (
  measurementsValues: MeasurementInputValues = measurementsInitialValues,
): MeasurementInputValues => {
  const res = {
    ...measurementsValues,
    [MEASUREMENTS_FORM_FIELDS.wasteFactor]: +toFixed(
      1 + (measurementsValues[MEASUREMENTS_FORM_FIELDS.wasteFactor] as number) / 100,
      2,
    ),
  };

  if (
    !(
      MEASUREMENTS_FORM_FIELDS.isIceWaterSettingsOn in measurementsValues ||
      MEASUREMENTS_FORM_FIELDS.isDeckingAndSheathingOn in measurementsValues
    )
  ) {
    return res;
  }

  for (const field in measurementsDependingFields) {
    if (!measurementsValues[field]) {
      for (const dependant of measurementsDependingFields[field]) {
        res[dependant] = 0;
      }
    }
  }

  return res;
};

export const getQuantity = (
  formula: string[],
  _measurementsValues: MeasurementInputValues = measurementsInitialValues,
): {
  quantity: number;
  isError: boolean;
  formulaWithValues: string;
} => {
  try {
    const measurementsValues = transformMeasurementValuesForCalculations(_measurementsValues);
    const formulaWithValues = formula.map(item => {
      const value = measurementsValues[item];
      if (value === undefined || typeof value === 'boolean') {
        return item;
      }
      return value;
    }) as (string | number)[];
    const polishNotation = transformInfixToPostfix(formulaWithValues);
    const quantity = Math.ceil(calculateUsingPolishNotation(polishNotation));
    return {
      quantity: quantity < 0 || quantity === Infinity || isNaN(quantity) ? 0 : quantity,
      isError: false,
      formulaWithValues: formulaWithValues.join(''),
    };
  } catch (e) {
    return { quantity: 0, isError: true, formulaWithValues: '' };
  }
};

export const getFormulaForProduct = (
  formulas: Formula[],
  productId: string,
): Formula | undefined => {
  return formulas.find(formula =>
    formula.assignments.some(assignment => assignment.productId === productId),
  );
};

export const transformAssignmentsValueToFormulaAssignments = (
  assignments: Assignment[],
): Assignment[] => {
  return assignments.reduce((acc: Assignment[], assignment) => {
    if (!(assignment.accountId && assignment.productId && assignment.templateIds.length)) {
      return acc;
    }
    if (assignment.parentAssignments.length) {
      return [...acc, ...assignment.parentAssignments];
    }
    assignment.itemTemplateIds.forEach((templateId, index) => {
      const templateName = assignment.itemTemplateNames[index];
      acc.push({
        ...assignment,
        templateId,
        templateName,
        fullName: `${assignment.accountName} | ${templateName} | ${assignment.productName}`,
      });
    });
    return acc;
  }, []);
};

export const transformFormulaAssignmentToAssignmentsValue = (
  assignments: Assignment[],
): Assignment[] => {
  const joinedTemplateIds: Record<string, Assignment> = {};
  assignments.forEach(assignment => {
    const key = `${assignment.accountId}:${assignment.productId}`;

    if (!joinedTemplateIds[key]) {
      joinedTemplateIds[key] = {
        ...assignment,
        templateIds: [],
        itemTemplateIds: [],
        parentAssignments: [],
      };
    }
    const _assignment = joinedTemplateIds[key] as Assignment;
    const templateIds = [..._assignment.templateIds, assignment.templateId];
    joinedTemplateIds[key] = {
      ..._assignment,
      templateIds,
      itemTemplateIds: templateIds,
      parentAssignments: [..._assignment.parentAssignments, assignment],
    };
  });

  return Object.values(joinedTemplateIds);
};

export const addSpacesToFormulaPreview = (str = '') => str.replace(/([\+\-\*\/])/g, ' $1 ');

export const restoreDeletedMeasurementsFields = (formulas: Formula[]): Formula[] =>
  formulas.map(formula => {
    const deletedFields = Object.keys(deletedMeasurementsFormFieldsBackup).filter(key =>
      formula.entity.value.includes(key),
    );
    if (!deletedFields.length) {
      return formula;
    }
    deletedFields.forEach(deletedField => {
      const ind = formula.entity.value.indexOf(deletedField);
      formula.entity.value.splice(
        ind,
        1,
        ...deletedMeasurementsFormFieldsBackup[deletedField].value,
      );
      formula.entity.preview = formula.entity.preview.replace(
        deletedMeasurementsFormFieldsBackup[deletedField].name,
        deletedMeasurementsFormFieldsBackup[deletedField].preview,
      );
    });
    return formula;
  });

export const mapProductDetailsToTemplateProduct = ({
  productDetails,
  skusVariation,
  itemNumber,
}: {
  productDetails: TemplateItemDetails;
  skusVariation?: {
    [k: number]: {
      [k in keyof typeof Variations]?: string[];
    };
  };
  itemNumber: string;
}): TemplateProduct | undefined =>
  productDetails
    ? {
        templateItemId: '',
        imageUrl: {
          thumbnail: productDetails.currentSKU.itemImage,
          large: '',
          swatch: '',
        },
        productOnErrorImageUrl: productDetails.product.productOnErrorImage,
        itemNumber,
        nickName: '',
        productOrItemNumber: productDetails.product.productId,
        itemOrProductDescription: productDetails.product.productName,
        variations: productDetails.variations,
        skusVariation: skusVariation || {},
        quantity: 0,
        unitPrice: productDetails.currentSKU.unitPrice,
        unitOfMeasure: productDetails.currentSKU.currentUOM,
        available: false,
        checked: true,
        formulaQuantity: 0,
        formulaWithValues: '',
        includingTemplates: [],
      }
    : undefined;
