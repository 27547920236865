import api from 'services/api';
import {
  MarketQuoteSettingsDto,
  PublicQuoteSettingsModel,
  QuoteSettingsDto,
} from 'modules/quoteSettings/types';
import { StructureSlopeEnum } from 'modules/quickQuote/types';

export const getQuoteSettingsApi = (userId: number) =>
  api.get<{ data: QuoteSettingsDto }>(`user/${userId}/quote-settings`);

export const updateQuoteSettingsApi = (userId: number, body: Partial<QuoteSettingsDto>) =>
  api.patch(`user/${userId}/quote-settings`, body);

export const getMarketQuoteSettings = (userId: number) =>
  api.get<{ data: MarketQuoteSettingsDto[] }>(`user/${userId}/market-quote-settings`);

export const updateMarketQuoteSettings = (
  userId: number,
  body: Record<string, StructureSlopeEnum>,
) => api.post(`user/${userId}/update-market-quote-settings`, body);

export const applyMarketsQuoteSettings = (userId: number, body: { slope: StructureSlopeEnum }) =>
  api.post(`user/${userId}/apply-market-quote-settings`, body);

export const getCompanyPublicQuoteSettingsApi = async (wildcard: string) =>
  api.get<{ data: PublicQuoteSettingsModel }>(`/widget/${wildcard}/company-quote-settings`);
