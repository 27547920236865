import { useSelector } from 'react-redux';

import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { constants as financingConstants, services as financingServices } from 'modules/financing';
import * as widgetSelectors from 'modules/widget/selectors';

export const useStatesUnavailableForMomnt = (ignoreUnavailableStates?: boolean) => {
  const { wildcard } = useSelector(widgetSelectors.selectWidget);

  return useAppQuery<string[]>(
    [financingConstants.UNAVAILABLE_STATES_KEY],
    () => financingServices.getStatesUnavailableForMomntAPI(wildcard || ''),
    {
      enabled: !!wildcard && !ignoreUnavailableStates,
    },
  );
};
