import { useSelector } from 'react-redux';

import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import {
  CompanyEmailSetting,
  CompanyNotificationsSettings,
  constants,
  services,
} from 'modules/emailsSettings';
import { selectors as userSelectors } from 'modules/auth';
import { getAdminId } from 'modules/auth/storage';
import { isAdmin } from 'modules/auth/utils';

export const useGetCompanyEmailSettings = () => {
  const user = useSelector(userSelectors.selectUser);
  const ignoreAdmin = isAdmin(user) && !getAdminId();

  return useAppQuery<{
    emailSettings: { [slug: string]: CompanyEmailSetting };
    isTheSameEmailsUsed: boolean;
  }>([constants.COMPANY_EMAIL_SETTINGS_KEY], () => services.getCompanyEmailSettingsAPI(user.id), {
    enabled: !!user.id && !ignoreAdmin,
  });
};

export const useGetCompanyNotificationsSettings = () => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<CompanyNotificationsSettings>(
    [constants.COMPANY_NOTIFICATIONS_SETTINGS_KEY],
    () => services.getCompanyNotificationsSettingsAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};
