import React from 'react';
import { Layout as LayoutBase } from 'antd';
import styled from 'styled-components';
import { LayoutProps } from 'antd/lib/layout';

import Header from './Header';
import Sidebar from './navigation/Sidebar';

const { Content: ContentBase } = LayoutBase;

interface Props {
  children: React.ReactNode;
  title?: string;
  description?: string;
  protectedRoute?: boolean;
  image?: string;
  showHeaderWithSearch?: boolean;
  className?: string;
  head?: JSX.Element;
}

const LayoutComponent: React.FC<Props> = ({ children, protectedRoute, className }) => {
  return (
    <Layout className={className} protectedRoute={protectedRoute}>
      <Content protectedRoute={protectedRoute}>
        <Header />
        {protectedRoute && <Sidebar />}
        {children}
      </Content>
    </Layout>
  );
};

export const Layout = styled(
  ({
    protectedRoute,
    ...rest
  }: LayoutProps & {
    protectedRoute?: boolean;
  }) => <LayoutBase {...rest} />,
)`
  min-height: 100%;
  padding-top: ${props => props.theme.elementsSizes.header.initial};

  @media ${props => props.theme.mediaQueries.medium} {
    padding-top: ${props => props.theme.elementsSizes.header.initial};

    ${props =>
      props.protectedRoute && `padding-top: calc(${props.theme.elementsSizes.header.initial});`}
  }

  @media ${props => props.theme.mediaQueries.small} {
    padding-top: ${props => props.theme.elementsSizes.header.small};

    ${props =>
      props.protectedRoute && `padding-top: calc(${props.theme.elementsSizes.header.small});`}
  }
`;

const Content = styled(
  ({
    protectedRoute,
    ...rest
  }: LayoutProps & {
    protectedRoute?: boolean;
  }) => <ContentBase {...rest} />,
)`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;

  padding-left: 84px;
  transition: padding-left 0.3s;
  ${props => !props.protectedRoute && 'padding-left: 0;'}

  @media ${props => props.theme.mediaQueries.medium} {
    padding-left: 0;
  }
`;

export default LayoutComponent;
