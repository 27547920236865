import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuItem } from '../types';

type UseIsActiveCheckType = () => (items: MenuItem) => MenuItem;
export const useIsActiveCheck: UseIsActiveCheckType = () => {
  const location = useLocation();

  return useCallback(
    (item: MenuItem) => ({ ...item, isActive: location.pathname === item.link }),
    [location],
  );
};
