import * as constants from 'modules/mapbox/constants';
import { MapboxState } from 'modules/mapbox/types';
import { RoofleStructure } from 'modules/quickQuote';
import { setInitialStructures } from 'modules/quickQuote/actions';
import { ThunkResult } from 'types';
import { selectors } from 'modules/quickQuote';
import { mapboxCreateMap } from 'modules/mapbox/utils';

export const setMapboxAll = (mapboxAll: Partial<MapboxState>) => ({
  type: constants.SET_MAPBOX_ALL,
  ...mapboxAll,
});

export const initMapboxMap =
  ({ centerpoint }: { centerpoint: [number, number] }): ThunkResult<Promise<void>> =>
  async dispatch => {
    const { initialStructures, mapboxMap } = await mapboxCreateMap({
      centerpoint,
    });

    dispatch(
      setMapboxState({
        structures: initialStructures,
        mapState: {
          mapboxMap,
        },
      }),
    );
  };

export const setMapboxState = ({
  mapState,
  structures,
}: {
  mapState: Partial<MapboxState>;
  structures: RoofleStructure[];
}): ThunkResult<Promise<void>> => {
  return async (dispatch, getState) => {
    const { mapboxMap } = mapState;
    const { isLoaded } = selectors.selectQuickQuote(getState());

    if (!mapboxMap) {
      return;
    }

    if (isLoaded) {
      dispatch(setInitialStructures(structures));
    }

    dispatch(
      setMapboxAll({
        mapboxMap,
        mapboxIsReady: true,
      }),
    );
  };
};

export const setMapboxIsEditing = ({ isEditing }: { isEditing: MapboxState['isEditing'] }) => ({
  isEditing,
  type: constants.SET_MAPBOX_IS_EDITING,
});

export const setMapboxIsAddingPolygons = ({
  isAddingPolygons,
}: {
  isAddingPolygons: MapboxState['isAddingPolygons'];
}) => ({
  isAddingPolygons,
  type: constants.SET_MAPBOX_IS_ADDING_POLYGONS,
});

export const setMapboxIsPolygonSelected = ({
  isPolygonSelected,
}: {
  isPolygonSelected: MapboxState['isPolygonSelected'];
}) => ({
  isPolygonSelected,
  type: constants.SET_MAPBOX_IS_POLYGON_SELECTED,
});

export const updateMapboxDrawPolygonClickCount = (polygonClickCount?: number) => ({
  polygonClickCount,
  type: constants.SET_MAPBOX_DRAW_POLYGON_CLICK_COUNT,
});

export const setMapboxVertexHelpText = () => ({
  type: constants.SET_MAPBOX_VERTEX_HELP_TEXT,
});

export const setMapboxPolygonHelpText = () => ({
  type: constants.SET_MAPBOX_POLYGON_HELP_TEXT,
});

export const setMapboxNoSelectionHelpText = () => ({
  type: constants.SET_MAPBOX_NO_SELECTION_HELP_TEXT,
});

export const clearMapbox = () => ({
  type: constants.CLEAR_MAPBOX,
});
