import omit from 'lodash/omit';
import pick from 'lodash/pick';
import intersection from 'lodash/intersection';
import differenceWith from 'lodash/differenceWith';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';

import { Formula, TemplateItem, utils, TemplateItemDetails, constants } from 'modules/orders';
import { TemplateProduct, MeasurementInfo } from './types';

export const formatTemplateProducts = ({
  products,
  formulas,
  measurementsValues,
  savedProducts,
  details,
}: {
  products: TemplateItem[];
  formulas: Formula[];
  measurementsValues: MeasurementInfo;
  savedProducts?: TemplateProduct[];
  details: { [key: string]: TemplateItemDetails | null };
}): TemplateProduct[] => {
  return products.map(item => {
    const savedProduct = savedProducts?.find(
      _savedProduct => _savedProduct.itemNumber === item.itemNumber,
    );
    const productDetails = details[item.itemNumber];
    const itemNumber = productDetails ? productDetails.product.itemNumber : item.itemNumber;

    const updatedProduct: TemplateProduct = {
      ...item,
      itemNumber: savedProduct ? savedProduct.itemNumber : itemNumber,
      checked: savedProduct ? savedProduct.checked : true,
      quantity: savedProduct ? savedProduct.quantity : 0,
      formulaQuantity: 0,
      formula: undefined,
      formulaWithValues: '',
      unitOfMeasure: productDetails ? productDetails.currentSKU.currentUOM : item.unitOfMeasure,
      unitPrice: productDetails ? productDetails.currentSKU.unitPrice : item.unitPrice,
      itemOrProductDescription: productDetails
        ? productDetails.product.productName
        : item.itemOrProductDescription,
      imageUrl: {
        ...item.imageUrl,
        thumbnail: productDetails ? productDetails.currentSKU.thumbImage : item.imageUrl.thumbnail,
      },
    };

    if (savedProduct?.selectedVariations) {
      Object.assign(updatedProduct, { selectedVariations: savedProduct.selectedVariations });
    }

    const formula = utils.getFormulaForProduct(formulas, updatedProduct.productOrItemNumber);
    if (formula) {
      updatedProduct.formula = formula;
      updatedProduct.formula.entity.preview = utils.addSpacesToFormulaPreview(
        updatedProduct.formula.entity.preview,
      );
      const { quantity, isError, formulaWithValues } = utils.getQuantity(
        formula.entity.value,
        measurementsValues,
      );
      if (!isError) {
        if (!savedProduct) {
          updatedProduct.quantity = quantity;
        }
        updatedProduct.formulaQuantity = quantity;
        updatedProduct.formulaWithValues = utils.addSpacesToFormulaPreview(formulaWithValues);
      }
    }

    return updatedProduct;
  });
};

export const updateProductWithNewDetails = (
  product: TemplateProduct,
  details: TemplateItemDetails | undefined,
): TemplateProduct => {
  if (!details) {
    return product;
  }
  return {
    ...product,
    itemNumber: details.product.itemNumber,
    unitPrice: details.currentSKU.unitPrice,
    unitOfMeasure: details.currentSKU.currentUOM,
    itemOrProductDescription: details.product.productName,
    imageUrl: {
      ...product.imageUrl,
      thumbnail: details.currentSKU.thumbImage,
    },
  };
};

export const findSuitableItemNumbers = (
  variationKey: string,
  variantValue: string,
  product: Pick<TemplateProduct, 'variations' | 'skusVariation' | 'itemNumber'>,
): string[] => {
  const leftVariationsKeys = Object.keys(omit(product.variations, variationKey));
  const codes = product.variations[variationKey][variantValue] as string[];
  const currentValues = Object.values(
    pick(product.skusVariation[product.itemNumber], leftVariationsKeys),
  ).flat();

  const suitableCodes = currentValues
    .map(item => {
      const leftVariationsValues = Object.values(omit(product.variations, variationKey)) as Record<
        string,
        string[]
      >[];
      const variantObj = leftVariationsValues.find(obj => item in obj);

      return variantObj ? variantObj[item] : [];
    })
    .flat();

  return intersection(codes, suitableCodes);
};

export const checkIfVariantValueSuitable = (
  variationKey: string,
  variantValue: string,
  product: Pick<TemplateProduct, 'variations'>,
  amountOfVariationCodes: Record<string, number>,
): boolean => {
  const codes = product.variations[variationKey][variantValue] as string[];

  return (
    Object.keys(product.variations).length === 1 ||
    codes.some(code => amountOfVariationCodes[code] >= Object.keys(product.variations).length - 1)
  );
};

export const getProductEstimatedPrice = (
  products: { checked: boolean; quantity: number; unitPrice: number }[],
) => {
  return +products
    .filter(product => product.checked)
    .reduce((total, product) => total + product.quantity * product.unitPrice, 0)
    .toFixed(2);
};

export const getSuitableVariations = (
  product: Pick<TemplateProduct, 'skusVariation'>,
  code: number,
  excludeVariations: string[] = [],
): Record<string, number> => {
  const variations = product.skusVariation[code];

  return Object.entries(omit(variations, excludeVariations)).reduce(
    (acc, [key, values]) => Object.assign(acc, { [key]: values[0] }),
    {},
  );
};

export const getProductsWithUpdatedQuantities = ({
  measurementsValues,
  measurementsPrevValues,
  products,
}: {
  measurementsValues: MeasurementInfo;
  measurementsPrevValues: MeasurementInfo;
  products: TemplateProduct[];
}): TemplateProduct[] => {
  const changes = fromPairs(
    differenceWith(toPairs(measurementsValues), toPairs(measurementsPrevValues), isEqual),
  );
  const changedFields = Object.keys(changes);
  for (const field in constants.measurementsDependingFields) {
    if (field in changes) {
      changedFields.push(...constants.measurementsDependingFields[field]);
    }
  }

  return products.map(product => {
    if (!product.formula) {
      return product;
    }
    if (!product.formula.entity.value.some(formulaItem => changedFields.includes(formulaItem))) {
      return product;
    }

    const { quantity, formulaWithValues } = utils.getQuantity(
      product.formula.entity.value,
      measurementsValues,
    );

    return {
      ...product,
      quantity: quantity,
      formulaQuantity: quantity,
      formulaWithValues: utils.addSpacesToFormulaPreview(formulaWithValues),
    };
  }, []);
};
