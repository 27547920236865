import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { I18nEnum } from 'types';
import { useIsAdminUser } from 'modules/hooks/useCheckPermissions';
import { ActionType } from 'components/PopupMenu';
import Tooltip from 'components/Tooltips/Tooltip';
import { NavigationPopup } from './NavigationPopup';
import {
  MenuItemContainer,
  MenuItemImg,
  NavPopupIcon,
  NavigationIconContainer,
  NavigationPopupContainer,
  Notification,
} from './common-components';
import { useSettingsMenu } from './hooks/useSettingsMenu';
import useMostSevereNotificationType from './hooks/useMostSevereNotificationType';

const titleToDescriptionMap: { [key in I18nEnum]?: I18nEnum } = {
  [I18nEnum.General]: I18nEnum.OptimizeAllOfYourWidget,
  [I18nEnum.Markets]: I18nEnum.ControlYourMarketServiceArea,
  [I18nEnum.Products]: I18nEnum.SelectAndConfigureYourProduct,
  [I18nEnum.Developer]: I18nEnum.InstallYourInstantQuoteTool,
} as const;

export const SettingsMenu = () => {
  const navigate = useNavigate();
  const isAdminUser = useIsAdminUser();
  const menu = useSettingsMenu();
  const mostSevereNotificationType = useMostSevereNotificationType(menu);

  const actions: ActionType[] = useMemo(
    () =>
      menu.map(item => ({
        img: (
          <Tooltip
            tooltip={item.notification?.tooltip}
            isShown={!!item.notification}
            handleClose={null}>
            <NavigationIconWrapper>
              <MenuItemImg src={item.iconSrc} />
              {item.notification && <Notification type={item.notification.type} />}
            </NavigationIconWrapper>
          </Tooltip>
        ),
        label: item.title,
        onClick: () => navigate(item.link),
        isItemActive: item.isActive,
        Wrapper: props => (
          <MenuItemContainer
            {...props}
            description={<FormattedMessage id={titleToDescriptionMap[item.title]} />}
          />
        ),
      })),
    [navigate, menu],
  );

  const isSomeItemActive = useMemo(() => menu.some(item => item.isActive), [menu]);

  if (!actions.length && !isAdminUser) {
    return null;
  }

  return (
    <NavigationPopupContainer>
      <NavigationPopup
        actions={actions}
        disabled={!actions.length}
        icon={
          <NavigationIconContainer isActive={isSomeItemActive}>
            <NavigationIconWrapper>
              <NavPopupIcon src="/images/menu/settings-icon.svg" disabled={!actions.length} />
              {mostSevereNotificationType && <Notification type={mostSevereNotificationType} />}
            </NavigationIconWrapper>
          </NavigationIconContainer>
        }
        tooltipSettings={{
          isShown: !actions.length,
          tooltip: <FormattedMessage id={I18nEnum.SettingsAreOnlyAvailable} />,
          placement: 'bottom',
        }}
      />
    </NavigationPopupContainer>
  );
};

const NavigationIconWrapper = styled.div`
  position: relative;
`;
