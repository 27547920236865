import { useEffect, useState } from 'react';

export const useBroadcastChannelProvider = (channelId: string) => {
  const channel = new BroadcastChannel(channelId);

  useEffect(() => {
    channel.postMessage({ alive: true });

    return () => {
      channel.postMessage({ alive: false });
      channel.close();
    };
  }, []);

  return channel;
};

export const useBroadcastChannelConsumer = (channelId: string) => {
  const [isProviderAlive, setIsProviderAlive] = useState(false);
  const channel = new BroadcastChannel(channelId);

  useEffect(() => {
    channel.onmessage = event => {
      if ('alive' in event.data) {
        setIsProviderAlive(event.data.alive as boolean);
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  return {
    channel,
    isProviderAlive,
  };
};
