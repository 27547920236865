import { useState, useEffect } from 'react';

import trackError from 'services/trackError';

function useLocalStorage<T>(key: string, initialValue?: T): [T, (s: T) => void] {
  const [storedValue, setStoredValue] = useState<any>();

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      trackError(`Set in LS ${key} -`, error);
    }
  };

  useEffect(() => {
    if (storedValue) {
      return;
    }

    try {
      const item = localStorage.getItem(key);

      setStoredValue(item ? JSON.parse(item) : null);
    } catch (error) {
      trackError(`Get from LS ${key} -`, error);
      setStoredValue(initialValue);
    }
  }, [key, initialValue, storedValue]);

  return [storedValue as any, setValue];
}

export default useLocalStorage;
