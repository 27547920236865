import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { I18nEnum } from 'types';
import { selectors as userSelectors } from 'modules/auth';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { actions as modalActions } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { EagleviewConnection } from '../types';
import { editEagleviewConnectionAPI } from '../services';
import { EAGLE_VIEW_CONNECTION_KEY } from '../constants';

export const useEditEagleviewConnection = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<Partial<EagleviewConnection>[]>({
    mutationFn: variables => editEagleviewConnectionAPI(user.id, variables),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.EagleviewWasEditedSuccessfully }),
        ),
      );
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [EAGLE_VIEW_CONNECTION_KEY],
        exact: true,
      });
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheEagleviewWasNotEdited }),
        ),
      );
    },
  });
};
