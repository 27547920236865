import * as services from './services';
import { ThunkResult, I18nEnum } from 'types';
import { selectors as userSelectors, actions as authActions } from 'modules/auth';
import { actions as spinnerActions } from 'modules/spinner';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { actions as modalActions } from 'modules/modal';
import { setUser } from 'modules/auth/actions';

export const updatePasswordSuccess = (): ThunkResult<void> => dispatch => {
  dispatch(modalActions.closeModal());
  dispatch(messageActions.openMessage(MessageTypes.success, I18nEnum.YourPasswordHasBeenChanged));
};

export const updatePasswordError =
  (error): ThunkResult<void> =>
  (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());

    if (error.code === 400 || error.code === 402) {
      dispatch(setUser({ ...user, error }));
    } else {
      console.log(error);
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          I18nEnum.UnfortunatelyYourPasswordNotChanged,
        ),
      );
    }
  };

export const deleteAccount = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(spinnerActions.show());
    const user = userSelectors.selectUser(state);
    await services.deleteAccountApi(user.id);

    dispatch(modalActions.closeModal());
    dispatch(authActions.clearUser());

    dispatch(spinnerActions.hide());
    dispatch(
      messageActions.openMessage(MessageTypes.success, 'I18nEnum.YourAccountHasBeenDeleted'),
    );
  } catch (error) {
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(MessageTypes.error, 'I18nEnum.Oops'));
    console.log(error);
  }
};
