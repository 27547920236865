import api from 'services/api';
import { ListQuery } from 'types';
import {
  Formula,
  MarketsAccounts,
  OrdersQuery,
  AssignmentMatchBody,
  BeaconProductsRequestParams,
} from 'modules/orders/types';
import { UpsertOrderBody } from 'modules/orders/components/CreateOrder/types';

export const getAccountsAPI = (userId: number) => api.get(`user/${userId}/beacon/accounts`);
export const getTemplatesAPI = (userId: number, accountId: string, params?: ListQuery) =>
  api.get(`user/${userId}/beacon/account/${accountId}/templates`, { params });
export const getTemplateDetailsAPI = (
  userId: number,
  accountId: string,
  templateId: string,
  jobNumber?: string,
) => {
  const params = !jobNumber ? {} : { jobNumber };
  return api.get(`user/${userId}/beacon/account/${accountId}/template/${templateId}`, { params });
};

export const getTemplateItemDetailsAPI = (
  userId: number,
  accountId: string,
  productId: string,
  itemNumber: string,
  jobNumber?: string,
) => {
  const params = !jobNumber ? {} : { jobNumber };
  return api.get(
    `user/${userId}/beacon/account/${accountId}/product/${productId}/item/${itemNumber}`,
    { params },
  );
};

export const assignBeaconAccountsToMarketsAPI = (userId: number, body: MarketsAccounts) =>
  api.post(`user/${userId}/beacon/markets/assign`, body);

export const getOrdersHistoryAPI = (userId: number, params: OrdersQuery) =>
  api.get(`user/${userId}/beacon/orders-history`, { params });
export const getOrderDetailsAPI = (userId: number, accountId: string, orderId: string) =>
  api.get(`user/${userId}/beacon/orders-history/${accountId}/${orderId}`);
export const downloadOrderDetailsPdfAPI = (userId: number, accountId: string, orderId: string) =>
  api.get(`user/${userId}/beacon/download-pdf/${accountId}/${orderId}`, {
    responseType: 'blob',
  });

// used post because of Too Large Request Header
export const getTemplatesItemsAPI = (userId: number, accountId: string, templateIds: string[]) =>
  api.post(`user/${userId}/beacon/account/${accountId}/templates-items`, { templateIds });

export const upsertFormulaAPI = (userId: number, body: Formula) =>
  api.post(`user/${userId}/formula`, body);
export const getFormulasAPI = (userId: number, params: ListQuery) =>
  api.get(`user/${userId}/formulas`, { params });
export const deleteFormulaAPI = (userId: number, formulaId: number) =>
  api.delete(`user/${userId}/formula/${formulaId}`);
export const findAssignmentMatchAPI = (userId: number, body: AssignmentMatchBody) =>
  api.post(`user/${userId}/formula/assignment-match`, body);

export const saveDraftOrderApi = (userId: number, accountId: string, body: UpsertOrderBody) =>
  api.post(`user/${userId}/beacon/account/${accountId}/order`, body);
export const createOrderApi = (userId: number, accountId: string, body: UpsertOrderBody) =>
  api.post(`user/${userId}/beacon/account/${accountId}/create-order`, body);
export const deleteDraftOrderAPI = (userId: number, orderId: number) =>
  api.delete(`user/${userId}/beacon/order/${orderId}`);

export const getJobsAPI = (userId: number, accountId: string) =>
  api.get(`user/${userId}/beacon/account/${accountId}/jobs`);

export const getOrderTaxRatesAPI = (userId: number, state: string, zipcode: string) =>
  api.get(`user/${userId}/beacon/order/tax-rates`, { params: { state, zipcode } });

export const getProducts = (userId: number, params: BeaconProductsRequestParams) =>
  api.get(`user/${userId}/beacon/products`, { params });

export const getProductVariations = (userId: number, productId: string) =>
  api.get(`user/${userId}/beacon/product/${productId}/variations`);
