import styled from 'styled-components';

import { ActionItem } from 'components/PopupMenu';
import { NotificationType, NotificationTypes } from './types';

export const MenuItemImg = styled.img`
  filter: ${props => props.theme.colorFilters.blue};

  @media ${props => props.theme.mediaQueries.medium} {
    filter: ${props => props.theme.colorFilters.grey};
  }
`;

interface IMenuItemContainerProps {
  children: React.ReactNode;
  description: React.ReactNode;
  className?: string;
  onClick?: () => void;
  isActive?: boolean;
}
export const MenuItemContainer = ({
  children,
  description,
  className,
  onClick,
  isActive,
}: IMenuItemContainerProps) => (
  <MenuItemContainerRoot className={className} onClick={onClick} isActive={!!isActive}>
    {children}
    <MenuItemDescription>{description}</MenuItemDescription>
  </MenuItemContainerRoot>
);

const navigationPopupContainerPadding = 6;

const MenuItemContainerRoot = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 24px;
  cursor: pointer;

  ${ActionItem} {
    height: unset;
    width: min-content;
    padding: 0;
    column-gap: 4px;

    &:hover {
      background-color: transparent;
    }

    span {
      color: ${({ theme }) => theme.colors.blue};
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.37px;
      text-decoration-line: underline;
    }
  }

  img {
    height: 24px;
    width: 24px;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    border-left: 4px solid ${theme.colors.blue};
    padding-left: 20px;
  `};

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

export const MenuItemDescription = styled.div`
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.6;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.29px;
  margin: 3px 0 0 28px;
`;

export const NavPopupIcon = styled.img<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  opacity: 0.6;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.2;
    `}
`;

export const getActiveNavigationPopupIconCss = (theme, width?: number) => `
  &:after {
    content: '';
    display: block;
    height: 4px;
    width: ${width ? `${width + navigationPopupContainerPadding * 2}px` : '100%'};
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${theme.colors.blue};
  }
`;
export const NavigationIconContainer = styled.div<{ isOpen?: boolean; isActive: boolean }>`
  ${({ isOpen, theme }) =>
    isOpen &&
    `
      ${NavPopupIcon} {
        opacity: 1;
        filter: ${theme.colorFilters.blue};
      }
    `}

  ${({ isActive, theme }) =>
    isActive &&
    `
    ${NavPopupIcon} {
      opacity: 1;
      filter: ${theme.colorFilters.blue};
    }

    ${getActiveNavigationPopupIconCss(theme)}
  `}
`;

export const NavigationPopupContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${navigationPopupContainerPadding}px;
`;

export const Notification = styled.div<{ type: NotificationType }>`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};

  &:after {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: ${props => {
      switch (props.type) {
        case NotificationTypes.error:
          return props.theme.colors.error;
        case NotificationTypes.warning:
          return props.theme.colors.fuelYellow;
        default:
          return 'transparent';
      }
    }};
  }
`;
