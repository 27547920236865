import { useSelector } from 'react-redux';

import { selectUser } from 'modules/auth/selectors';
import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { services, types, constants } from '..';

export const useGetBeaconConnection = () => {
  const user = useSelector(selectUser);

  return useAppQuery<types.BeaconConnection>(
    [constants.BEACON_CONNECTION_KEY],
    () => services.getBeaconConnectionAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};
