import { useCallback, useEffect, useState } from 'react';
import { Typography as TypographyBase } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { I18nEnum, UserRoutes } from 'types';
import CloseIconBase from 'components/Icons/CloseIcon';
import { DeveloperTabKeys } from 'modules/developer/types';
import { getUserWidgetSettings } from 'modules/widget/actions';
import { actions as spinnerActions } from 'modules/spinner';
import { selectors as companySelectors } from 'modules/company';
import { selectors as userSelectors } from 'modules/auth';
import { selectWidget } from 'modules/widget/selectors';
import InfoIconBase from '../../../components/Icons/InfoIcon';

const WARNING_TYPE = {
  DOMAIN: 'domain',
} as const;
type WarningType = typeof WARNING_TYPE[keyof typeof WARNING_TYPE];

const warningTypeToMessageMap = {
  [WARNING_TYPE.DOMAIN]: (
    <FormattedMessage
      id={I18nEnum.YourDomainMustBe}
      values={{
        a: chunks => (
          <Link to={UserRoutes.developerInfo} state={{ tab: DeveloperTabKeys.ToolScripts }}>
            {chunks}
          </Link>
        ),
      }}
    />
  ),
};

const WarningList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const widget = useSelector(selectWidget);
  const user = useSelector(userSelectors.selectUser);
  const primaryUser = useSelector(companySelectors.selectPrimaryUser);

  const [warningTypes, setWarningTypes] = useState<WarningType[]>([]);

  const close = useCallback(
    (type: WarningType) => setWarningTypes(prevTypes => prevTypes.filter(t => t !== type)),
    [],
  );

  const getWarningMessageData = useCallback(async () => {
    dispatch(spinnerActions.show());

    try {
      await Promise.all([dispatch(getUserWidgetSettings())]);
    } catch (e) {
      console.error(e);
    }

    dispatch(spinnerActions.hide());
  }, [dispatch]);

  useEffect(() => {
    getWarningMessageData();
  }, [getWarningMessageData]);

  useEffect(() => {
    const _warningTypes = [] as WarningType[];

    [
      {
        isShown: widget && widget.loaded && !widget.domains.length,
        warningType: WARNING_TYPE.DOMAIN,
      },
    ].forEach(({ isShown, warningType }) => {
      if (isShown) {
        _warningTypes.push(warningType);
      }
    });

    setWarningTypes(_warningTypes);
  }, [location, widget, setWarningTypes, user.role, primaryUser]);

  return warningTypes.length ? (
    <Root>
      {warningTypes.map(type => (
        <WarningContainer key={type}>
          <InfoIcon size={{ height: 24, width: 24 }} />
          <Typography>{warningTypeToMessageMap[type]}</Typography>
          <CloseIcon small onClick={() => close(type)} id="warning" />
        </WarningContainer>
      ))}
    </Root>
  ) : null;
};

const Root = styled.div`
  position: fixed;
  right: 16px;
  bottom: 38px;
  z-index: 1006;

  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${props => props.theme.mediaQueries.small} {
    right: 10px;
    bottom: 18px;
  }
`;

const WarningContainer = styled.div`
  width: 455px;
  background-color: ${props => props.theme.colors.blue};
  padding: 24px 64px 24px 26px;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px -1px rgba(0, 0, 0, 0.2);

  @media ${props => props.theme.mediaQueries.small} {
    width: 253px;
    padding: 20px 52px 20px 28px;
  }
`;

const Typography = styled(TypographyBase)`
  color: ${props => props.theme.colors.white};
  opacity: 0.9;
  font-size: 16px;
  letter-spacing: 0.29px;
  line-height: 20px;
  font-weight: 500;
  white-space: pre-wrap;

  a,
  a:hover,
  a:visited {
    color: ${props => props.theme.colors.beige};
    text-decoration: underline;
    cursor: pointer;
  }

  @media ${props => props.theme.mediaQueries.small} {
    font-size: 14px;
    letter-spacing: 0.25px;
  }
`;

const InfoIcon = styled(InfoIconBase)`
  margin-right: 16px;
  path {
    fill: ${props => props.theme.colors.white};
  }
  opacity: 0.9;

  @media ${props => props.theme.mediaQueries.small} {
    margin-right: 10px;
  }
`;

const CloseIcon = styled(CloseIconBase)`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;

  #warning {
    fill: ${props => props.theme.colors.white};
    opacity: 0.6;
  }
`;

export default WarningList;
