import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { I18nEnum } from 'types';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { MessageTypes, actions as messageActions } from 'modules/message';
import { actions as modalActions } from 'modules/modal';
import {
  services,
  constants,
  ApplyEmailSettingsBody,
  CompanyEmailSetting,
  CompanyNotificationsSettings,
} from 'modules/emailsSettings';
import { constants as companyConstants } from 'modules/company';
import { selectors as userSelectors } from 'modules/auth';
import { queryClient } from 'modules/reactQuery';

export const invalidateEmailSettings = () =>
  queryClient.invalidateQueries({
    predicate: query =>
      query.queryKey.some(_queryKey =>
        [constants.COMPANY_EMAIL_SETTINGS_KEY, companyConstants.COMPANY_SMTP_SERVER].includes(
          _queryKey as string,
        ),
      ),
  });

export const useUseTheSameEmails = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ emailSettingId?: number }>({
    mutationFn: variables => {
      return services.useTheSameEmailsAPI(user.id, variables);
    },
    onSuccess: () => {
      invalidateEmailSettings();
      dispatch(modalActions.closeModal());
    },
  });
};

export const useUpdateEmailSettings = (isOutbound?: boolean) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<CompanyEmailSetting>({
    mutationFn: variables => {
      return isOutbound
        ? services.updateOutboundEmailAPI(user.id, variables)
        : services.updateInboundEmailAPI(user.id, variables);
    },
    onSuccess: () => {
      invalidateEmailSettings();
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.EmailChangesHaveBeenSaved }),
        ),
      );
    },
    onError: error => {
      if (error.code === 400) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.error,
            intl.formatMessage({ id: I18nEnum.UnfortunatelyEmailChanges }),
          ),
        );
      }
    },
  });
};

export const useApplyEmailSettings = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<ApplyEmailSettingsBody>({
    mutationFn: variables => {
      return services.applyEmailSettingsAPI(user.id, variables);
    },
    onSuccess: () => {
      invalidateEmailSettings();
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.ChangesHaveBeenApplied }),
        ),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChanges }),
        ),
      );
    },
  });
};

export const useResetEmailSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation({
    mutationFn: () => {
      return services.useTheSameEmailsAPI(user.id, {});
    },
    onSuccess: () => {
      invalidateEmailSettings();
      dispatch(modalActions.closeModal());
    },
  });
};

export const useUpdateCompanyNotificationsSettings = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: Partial<CompanyNotificationsSettings>; onSuccess: () => void }>({
    mutationFn: variables => {
      return services.updateCompanyNotificationsSettingsAPI(user.id, variables.body);
    },
    onSuccess: (_, { onSuccess }) => {
      queryClient.invalidateQueries([constants.COMPANY_NOTIFICATIONS_SETTINGS_KEY]);
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.ChangesHaveBeenSavedSuccessfully }),
        ),
      );
      onSuccess();
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyChangesHaveNotBeenSavedPleaseTryAgain }),
        ),
      );
    },
  });
};
