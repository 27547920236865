import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import {
  services as profileServices,
  constants as profileConstants,
  ProfileState,
} from 'modules/profile';
import { actions as modalActions } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';

export const useUpdateProfile = (props?: { successMessage: string; errorMessage: string }) => {
  const { successMessage, errorMessage } = props || {};

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useAppMutation<ProfileState>({
    mutationFn: profileData => profileServices.updateProfileApi(profileData),
    onSuccess: () => {
      dispatch(modalActions.closeModal());
      successMessage && dispatch(messageActions.openMessage(MessageTypes.success, successMessage));

      queryClient.invalidateQueries({
        queryKey: [profileConstants.PROFILE_KEY],
      });
    },
    onError: () => {
      errorMessage && dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};
