import { I18nEnum } from 'types';

export const NotificationTypes = {
  error: 'error',
  warning: 'warning',
} as const;
export type NotificationType = keyof typeof NotificationTypes;
export type NotificationTooltipData = {
  type: NotificationType;
  tooltip: JSX.Element;
};

export type MenuItem = {
  title: I18nEnum;
  iconSrc: string;
  link: string;
  notification?: NotificationTooltipData;
  isActive?: boolean;
};
