import api from 'services/api';
import { ProfileState, UpsertSmtpServerVariables } from '.';

export const getUserApi = (userId: number) => api.get(`/user/${userId}`);

export const updatePasswordApi = (userId: number, password: string, newPassword: string) =>
  api.post('/user/update-password', { userId, password, newPassword });

export const uploadPhotoApi = (userId: number, photo: string) =>
  api.post('/user/photo', { userId, photo });

export const deleteAccountApi = (userId: number) => api.delete(`/user/${userId}`);

export const checkIsConfirmedApi = (email: string) => api.get(`/user/isConfirmed/${email}`);

export const updateProfileApi = (user: Partial<ProfileState>) =>
  api.post(`/user/${user.id}/profile`, user);

export const upsertSmtpServer = (userId: number, smtpServer: UpsertSmtpServerVariables['body']) =>
  api.post(`/user/${userId}/smtp`, smtpServer);
