import { Navigate } from 'react-router-dom';

import { Routes } from 'types';
import useToken from './useToken';

interface Props {
  children: any;
}

const PreviewRouter: React.FC<Props> = ({ children }) => {
  const { authState } = useToken({});

  if (!authState.isValidated) {
    return null;
  }

  if (!authState.isAuthenticated) {
    return <Navigate to={Routes.signin} />;
  }

  return children;
};

export default PreviewRouter;
