import { useMemo } from 'react';

import { I18nEnum, UserRoutes } from 'types';
import { useFilterNavItemsByPermissions } from './useFilterNavItemsByPermissions';

import { useIsActiveCheck } from './useIsActiveCheck';
import useEmailsNotification from 'modules/emailsSettings/hooks/useEmailsNotification';

export const useSettingsMenu = () => {
  const checkIsActive = useIsActiveCheck();
  const filterNavItemsByPermissions = useFilterNavItemsByPermissions();

  const generalNotification = useEmailsNotification();

  const menu = useMemo(
    () =>
      filterNavItemsByPermissions([
        {
          iconSrc: '/images/svg/settings-suggest.svg',
          title: I18nEnum.General,
          link: UserRoutes.toolSettings,
          notification: generalNotification,
        },
        {
          iconSrc: '/images/menu/markets-icon.svg',
          title: I18nEnum.Markets,
          link: UserRoutes.marketsSettings,
        },
        {
          iconSrc: '/images/menu/products-icon.svg',
          title: I18nEnum.Products,
          link: UserRoutes.products,
        },
        {
          iconSrc: '/images/menu/developer-icon.svg',
          title: I18nEnum.Developer,
          link: UserRoutes.developerInfo,
        },
      ]).map(checkIsActive),
    [filterNavItemsByPermissions, checkIsActive, generalNotification],
  );

  return menu;
};
