import api from 'services/api';
import {
  SignUpLocalUserData,
  SignInLocalUserData,
  EmailConfirmProps,
  SignInSocialsUserData,
  ResetPasswordProps,
  ChangePasswordProps,
} from './types';

export const signInSocial = (userBody: SignInSocialsUserData) =>
  api.post('signin/social', userBody);

export const signUpLocal = (userBody: SignUpLocalUserData) => api.put('signup', userBody);

export const signInLocal = (userBody: SignInLocalUserData) => api.post('signin', userBody);

export const confirmEmail = (confirmationBody: EmailConfirmProps) =>
  api.post('signup/confirm', confirmationBody);

export const resendConfirmationEmail = (email: string) =>
  api.get(`signup/resend-confirmation/email/${email}`);

export const resendConfirmationEmailById = (userId: string | number) =>
  api.get(`signup/resend-confirmation/${userId}`);

export const forgotPassword = (email: string) =>
  api.post('user/reset-password/send-link', { email });

export const verifyResetPasswordLink = (resetPasswordData: ResetPasswordProps) =>
  api.get(
    `user/${resetPasswordData.userId}/reset-password/verify-link/${resetPasswordData.resetPasswordCode}`,
  );

export const resetPassword = (changePasswordData: ChangePasswordProps) =>
  api.post('user/reset-password', changePasswordData);

export const resendResetPassword = (email: string) =>
  api.get(`/user/email/${email}/reset-password/resend-link`);

export const resendResetPasswordById = (userId: string | number) =>
  api.get(`/user/${userId}/reset-password/resend-link`);

export const updateToken = (userId: string | number) => api.get(`/user/update-token/${userId}`);

export const recoverAccountApi = (userBody: SignInLocalUserData) =>
  api.post('user/recover', userBody);

export const getNotifications = (userId: number) => api.get(`user/${userId}/notifications`);

export const toggleReadNotification = (userId: number, notificationId: number) =>
  api.put(`user/${userId}/notifications/read/${notificationId}`);

export const deleteNotification = (userId: number, notificationId: number) =>
  api.delete(`user/${userId}/notifications/delete/${notificationId}`);

export const markNotificationsAsRead = (userId: number) =>
  api.put(`user/${userId}/notifications/read`);

export const batchReadNotifications = (userId: number, id: number[]) =>
  api.put(`user/${userId}/notifications/read/batch`, id);

export const verifyApprovalCodeAPI = (userId: number, approvalCode: string) =>
  api.get(`user/${userId}/verify-approval-code/${approvalCode}`);

export const getOAuthUrl = (userId: number, type = 'google') =>
  api.get(`/user/${userId}/oauth/get-url`, { params: { type } });
