import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import countBy from 'lodash/countBy';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import capitalize from 'lodash/capitalize';

import { AllPermissions } from 'modules/auth/types';
import { Variations, constants, queries } from 'modules/orders';
import { useCheckPermissions } from 'modules/hooks/useCheckPermissions';
import { OptionType } from 'components/Inputs/Select';
import { FormType, TemplateProduct } from '../components/CreateOrder/types';
import {
  checkIfVariantValueSuitable,
  findSuitableItemNumbers,
  getSuitableVariations,
} from '../components/CreateOrder/product-utils';
import {
  MATERIAL_TEMPLATE_FORM_FIELDS,
  MATERIAL_TEMPLATE_INFO_FORM_FIELD,
} from '../components/CreateOrder/constants';

export const useHasMyOrdersAccess = () => {
  const hasMyOrdersAccess = useCheckPermissions({
    permissionAccess: [AllPermissions.orders],
  });

  return hasMyOrdersAccess;
};

export const useHasAccountsManagementAccess = () => {
  const hasAccountsManagementAccess = useCheckPermissions({
    permissionAccess: [AllPermissions.ordersAccountsManagement],
  });

  return hasAccountsManagementAccess;
};

export const useHasDeveloperInfoAccess = () => {
  const hasDeveloperInfoAccess = useCheckPermissions({
    permissionAccess: [AllPermissions.developerInfo],
  });

  return hasDeveloperInfoAccess;
};

export const useGetTemplateFormulas = (templateId?: string) => {
  const { data: formulasData } = queries.useGetFormulas({
    ...constants.initialQuery,
    templateIds: templateId ? [templateId] : [],
  });

  return formulasData?.formulas;
};

type ProductOptionType = OptionType & {
  notSuitable: boolean;
  image?: string;
  itemNumber?: string;
};
type SelectedVariationsType = Record<string, string>;
export type BuiltVariations = {
  [k in keyof typeof Variations]?: {
    label: string;
    options: ProductOptionType[];
    defaultValue: string;
  };
};

type UseProductVariationsType = (options: {
  product: Pick<
    TemplateProduct,
    'variations' | 'selectedVariations' | 'skusVariation' | 'itemNumber' | 'templateItemId'
  >;
  onVariationChange?: (product: TemplateProduct, prevItemNumber: string, checked: boolean) => void;
  excludeVariationsFromAddedProducts?: boolean;
}) => {
  variations: BuiltVariations;
  optionContentRenderer: (optionContent, config) => JSX.Element;
  handleProductVariantChange: (value, key) => void;
};

export const useProductVariations: UseProductVariationsType = ({
  product,
  onVariationChange,
  excludeVariationsFromAddedProducts,
}) => {
  const form = Form.useFormInstance<FormType>();
  const [selectedVariations, setSelectedVariations] = useState<SelectedVariationsType>({});

  const optionContentRenderer = useCallback(
    (optionContent, config) => (
      <VariationOptionWrapper notSuitable={config.notSuitable}>
        {optionContent}
      </VariationOptionWrapper>
    ),
    [],
  );

  const amountOfVariationCodes = useMemo(() => {
    if (!product?.variations) {
      return {};
    }

    const allVariations = Object.assign({}, ...Object.values(product.variations).flat());
    const currentVariationsCodes = Object.values(selectedVariations)
      .map(item => allVariations[item])
      .flat();

    return countBy(currentVariationsCodes, item => item);
  }, [selectedVariations, product]);

  const variations = useMemo(() => {
    if (!product?.variations) {
      return {};
    }

    let addedProducts: TemplateProduct[];
    if (form) {
      addedProducts = (
        (form.getFieldValue([
          MATERIAL_TEMPLATE_INFO_FORM_FIELD,
          MATERIAL_TEMPLATE_FORM_FIELDS.templateProducts,
        ]) as TemplateProduct[]) || []
      ).filter(({ templateItemId }) => !templateItemId);
    }

    return Object.keys(product.variations).reduce(
      (acc, variation) => ({
        ...acc,
        [variation]: {
          label: capitalize(variation),
          defaultValue: selectedVariations[variation],
          options: sortBy(
            Object.keys(product.variations[variation] as { [k: string]: string[] }).map(key => {
              const isVariationSelected =
                !product.templateItemId &&
                excludeVariationsFromAddedProducts &&
                addedProducts.some(
                  p =>
                    p !== product &&
                    !isEmpty(p.skusVariation) &&
                    p.skusVariation[p.itemNumber] &&
                    p.skusVariation[p.itemNumber][variation] &&
                    p.skusVariation[p.itemNumber][variation][0] === key,
                );

              return {
                label: key,
                value: key,
                disabled: isVariationSelected,
                notSuitable:
                  isVariationSelected ||
                  !checkIfVariantValueSuitable(variation, key, product, amountOfVariationCodes),
              };
            }),
            ['label'],
          ),
        },
      }),
      {},
    );
  }, [
    product,
    amountOfVariationCodes,
    selectedVariations,
    excludeVariationsFromAddedProducts,
    form,
  ]);

  const handleProductVariantChange = useCallback(
    (value, key) => {
      const isSuitable = checkIfVariantValueSuitable(key, value, product, amountOfVariationCodes);
      const suitableItemNumbres = findSuitableItemNumbers(key, value, product);
      const variationItemNumber = product?.variations[key][value][0];
      const newItemNumber = suitableItemNumbres.length
        ? suitableItemNumbres[0]
        : variationItemNumber;

      const variationsToUpdate = isSuitable
        ? { ...selectedVariations, [key]: value }
        : { ...getSuitableVariations(product, newItemNumber, [key]), [key]: value };

      setSelectedVariations(variationsToUpdate);

      const prevItemNumber = product.itemNumber;

      onVariationChange &&
        onVariationChange(
          Object.assign({}, product, {
            itemNumber: newItemNumber,
            selectedVariations: variationsToUpdate,
          }) as TemplateProduct,
          prevItemNumber,
          true,
        );
    },
    [onVariationChange, product, selectedVariations, amountOfVariationCodes],
  );

  useEffect(() => {
    if (!product?.skusVariation) {
      return;
    }

    if (!isEmpty(product.selectedVariations)) {
      setSelectedVariations(product.selectedVariations);
      return;
    }

    const variationsMap = product.skusVariation[product.itemNumber];
    const availableVariationKeys = Object.keys(product.variations || {});

    if (!variationsMap || Object.keys(selectedVariations).length) {
      return;
    }

    setSelectedVariations(
      Object.entries(variationsMap)
        .filter(([key]) => availableVariationKeys.includes(key))
        .reduce((acc, [key, values]) => Object.assign(acc, { [key]: (values as string[])[0] }), {}),
    );
  }, [product, selectedVariations]);

  return {
    variations,
    optionContentRenderer,
    handleProductVariantChange,
  };
};

const VariationOptionWrapper = styled.span<{ notSuitable: boolean }>`
  ${props => props.notSuitable && 'opacity: 0.6;'};
`;
