import api from 'services/api';

export const trackRAIDSessionAPI = (raid: string, affiliateLink: string) =>
  api.post(`affiliate/${raid}/session`, { affiliateLink });

export const validateToken = (userId: number, token: string) =>
  api.get(`verify-token/${userId}`, {
    params: {
      userId,
    },
    headers: {
      'contractor-auth': token,
    },
  });
