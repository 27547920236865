import { I18nEnum } from 'types';
import {
  MEASUREMENTS_FORM_FIELDS,
  pitchOptions as pitchOptionsBase,
} from 'modules/orders/constants';

export const CUSTOMER_INFO_FORM_FIELD = 'customerInfo';
export const MEASUREMENTS_INFO_FORM_FIELD = 'measurementsInfo';
export const DELIVERY_INFO_FORM_FIELD = 'deliveryInfo';
export const MATERIAL_TEMPLATE_INFO_FORM_FIELD = 'materialTemplateInfo';

export const CUSTOMER_INFO_FORM_FIELDS = {
  purchasedOrder: 'purchasedOrder',
};

export const MATERIAL_TEMPLATE_FORM_FIELDS = {
  jobNumber: 'jobNumber',
  templateId: 'templateId',
  templateProducts: 'templateProducts',
};

export const ORDER_CREATION_STEP_KEYS = {
  customerInfo: 'customerInfo',
  measurementsInfo: 'measurementsInfo',
  materialTemplateInfo: 'materialTemplateInfo',
  deliveryInfo: 'deliveryInfo',
  reviewOrder: 'reviewOrder',
} as const;
export type OrderCreationStepKeys = keyof typeof ORDER_CREATION_STEP_KEYS;

export const DELIVERY_INFO_FORM_FIELDS = {
  shippingMethod: 'shippingMethod',
  shippingDate: 'shippingDate',
  shippingTime: 'shippingTime',
  shippingAddress: 'shippingAddress',
  shippingInstructions: 'shippingInstructions',
};

export const SHIPPING_METHODS = {
  shipToAddress: 'shipToAddress',
  pickUpInStore: 'pickUpInStore',
};

export const SHIPPING_TIME = {
  anytime: 'anytime',
  afternoon: 'afternoon',
  morning: 'morning',
  specialRequest: 'specialRequest',
};
export const SHIPPING_TIME_TO_INTL_KEY_MAP = {
  [SHIPPING_TIME.anytime]: I18nEnum.Anytime,
  [SHIPPING_TIME.afternoon]: I18nEnum.Afternoon,
  [SHIPPING_TIME.morning]: I18nEnum.Morning,
  [SHIPPING_TIME.specialRequest]: I18nEnum.SpecialRequest,
};

export const INSTANT_QUOTE_ID = -1;

export const commonMeasurementsConfig = [
  {
    title: I18nEnum.Hips,
    formItemName: MEASUREMENTS_FORM_FIELDS.hips,
    unit: I18nEnum.LF,
    reportFieldName: 'hips',
  },
  {
    title: I18nEnum.Ridges,
    formItemName: MEASUREMENTS_FORM_FIELDS.ridges,
    unit: I18nEnum.LF,
    reportFieldName: 'ridges',
  },
  {
    title: I18nEnum.Valleys,
    formItemName: MEASUREMENTS_FORM_FIELDS.valleys,
    unit: I18nEnum.LF,
    reportFieldName: 'valleys',
  },
  {
    title: I18nEnum.Eaves,
    formItemName: MEASUREMENTS_FORM_FIELDS.eaves,
    unit: I18nEnum.LF,
    reportFieldName: 'eaves',
  },
  {
    title: I18nEnum.Rakes,
    formItemName: MEASUREMENTS_FORM_FIELDS.rakes,
    unit: I18nEnum.LF,
    reportFieldName: 'rakes',
  },
  {
    title: I18nEnum.RidgeCap,
    formItemName: MEASUREMENTS_FORM_FIELDS.ridgeCap,
    unit: I18nEnum.LF,
    reportFieldName: 'ridgeCap',
  },
  {
    title: I18nEnum.Starter,
    formItemName: MEASUREMENTS_FORM_FIELDS.starter,
    unit: I18nEnum.LF,
    reportFieldName: 'starter',
  },
  {
    title: I18nEnum.Underlayment,
    formItemName: MEASUREMENTS_FORM_FIELDS.underlayment,
    unit: I18nEnum.LF,
    reportFieldName: 'underlayment',
  },
  {
    title: I18nEnum.DripEdge,
    formItemName: MEASUREMENTS_FORM_FIELDS.dripEdge,
    unit: I18nEnum.LF,
    reportFieldName: 'dripEdge',
  },
  {
    title: I18nEnum.StepFlashing,
    formItemName: MEASUREMENTS_FORM_FIELDS.stepFlashing,
    unit: I18nEnum.LF,
  },

  {
    title: I18nEnum.Flashing,
    formItemName: MEASUREMENTS_FORM_FIELDS.flashing,
    unit: I18nEnum.LF,
    reportFieldName: 'flashing',
  },
  {
    title: I18nEnum.Chimneys,
    formItemName: MEASUREMENTS_FORM_FIELDS.chimneys,
    unit: I18nEnum.QTY,
  },
  {
    title: I18nEnum.Skylights,
    formItemName: MEASUREMENTS_FORM_FIELDS.skylights,
    unit: I18nEnum.QTY,
  },
  {
    title: I18nEnum.Facets,
    formItemName: MEASUREMENTS_FORM_FIELDS.facets,
    unit: I18nEnum.QTY,
    reportFieldName: 'facets',
  },
  {
    title: I18nEnum.Pitch,
    formItemName: MEASUREMENTS_FORM_FIELDS.pitch,
    inputProps: { rightAligned: true },
    reportFieldName: 'pitch',
    isPitchDropdown: true,
  },
  {
    title: I18nEnum.Parapets,
    formItemName: MEASUREMENTS_FORM_FIELDS.parapets,
    unit: I18nEnum.QTY,
    reportFieldName: 'parapets',
  },
];

export const STEP_TO_FIELDS_MAP = {
  [ORDER_CREATION_STEP_KEYS.customerInfo]: {
    prefix: CUSTOMER_INFO_FORM_FIELD,
    fields: CUSTOMER_INFO_FORM_FIELDS,
  },
  [ORDER_CREATION_STEP_KEYS.measurementsInfo]: {
    prefix: MEASUREMENTS_INFO_FORM_FIELD,
    fields: MEASUREMENTS_FORM_FIELDS,
  },
  [ORDER_CREATION_STEP_KEYS.materialTemplateInfo]: {
    prefix: MATERIAL_TEMPLATE_INFO_FORM_FIELD,
    fields: MATERIAL_TEMPLATE_FORM_FIELDS,
  },
  [ORDER_CREATION_STEP_KEYS.deliveryInfo]: {
    prefix: DELIVERY_INFO_FORM_FIELD,
    fields: DELIVERY_INFO_FORM_FIELDS,
  },
};

export const PRESELECTED_JOBS = ['999', 'SHOP'];
export const DEFAULT_PRESELECTED_JOB = {
  jobName: 'SHOP ACCOUNT',
  jobNumber: 'SHOP',
};

export const MAX_TEMPLATE_PRODUCT_QUANTITY = 99999;

export const pitchOptions = [{ label: 0 as string | number, value: 0 }].concat(pitchOptionsBase);

export const ORDER_BLOCKER_KEY = 'orderBlocker';
