import { Link } from 'react-router-dom';
import { Layout, Typography as TypographyBase } from 'antd';
import styled from 'styled-components';
import { TypographyProps } from 'antd/lib/typography/Typography';

import { Routes } from 'types';
import { useIsAdminUser } from 'modules/hooks/useCheckPermissions';
import { ProfileMenu } from './navigation/ProfileMenu';
import { SettingsMenu } from './navigation/SettingsMenu';
import { MobileMenu } from './navigation/MobileMenu';
import SelectContractor from './SelectContractor';
import { useCheckPermissions } from 'modules/hooks/useCheckPermissions';
import { AllPermissions } from 'modules/auth/types';
import { useSignOut } from './navigation/hooks/useSignOut';

const { Header: HeaderBase } = Layout;

const HeaderComponent = () => {
  const isAdminUser = useIsAdminUser();
  const hasDashboardAccess = useCheckPermissions({
    permissionAccess: [AllPermissions.customerStatistics],
  });
  const handleSignOut = useSignOut();

  const isDisabled = isAdminUser || !hasDashboardAccess;

  const logo = (
    <Logo
      src={`${process.env.REACT_APP_ASSETS}cms/logo_header.svg`}
      alt="RoofQuote PRO™"
      disabled={isDisabled}
    />
  );

  return (
    <Header>
      <LogoContainer>{isDisabled ? logo : <Link to={Routes.home}>{logo}</Link>}</LogoContainer>
      <NavItems>
        {isAdminUser && <SelectContractor />}
        <SettingsMenu />
        <ProfileMenu signOutCallback={handleSignOut} />
        <MobileMenu signOutCallback={handleSignOut} />
      </NavItems>
    </Header>
  );
};

export const Header = styled(HeaderBase)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${props => props.theme.colors.white}cc;
  padding: 0 24px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  line-height: initial;
  align-items: center;
  backdrop-filter: saturate(180%) blur(5px);

  @media ${props => props.theme.mediaQueries.mediumXL} {
    padding: 0 10px;
  }

  @media ${props => props.theme.mediaQueries.medium} {
    top: 0;
    padding: 0 15px;

    .header-search {
      display: none;
    }
  }

  @media ${props => props.theme.mediaQueries.small} {
    height: ${props => props.theme.elementsSizes.header.small};
  }
`;

const Logo = styled.img<{ disabled?: boolean }>`
  height: 36px;
  margin-top: -6px;

  ${props => !props.disabled && 'cursor: pointer;'}
  @media ${props => props.theme.mediaQueries.medium} {
    width: 200px;
  }
`;

export const Typography = styled(
  ({ disabled, active, ...rest }: TypographyProps & { disabled?: boolean; active?: boolean }) => (
    <TypographyBase {...rest} />
  ),
)`
  font-size: 16px;
  padding-left: 32px;
  color: ${props => props.theme.colors.black};
  white-space: nowrap;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  ${props =>
    props.disabled &&
    `
    &, &:hover {
      opacity: 0.4;
      cursor: default;
    }
  `}
  ${props => props.active && `opacity: 0.9;`}

  @media ${props => props.theme.mediaQueries.mediumXL} {
    padding-left: 25px;
  }
`;

const LogoContainer = styled.div`
  &,
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${props => props.theme.mediaQueries.medium} {
    & > div {
      display: none;
    }
  }
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  height: 100%;
`;

export default HeaderComponent;
