import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { selectors as userSelectors } from 'modules/auth';
import { actions as modalActions } from 'modules/modal';
import { updateCompanyIntegrationAPI } from '../services';
import { IntegrationType } from '../types';
import { INTEGRATION_KEY } from '../constants';

type UseUpsertIntegrationProps = {
  successMessage: string;
  errorMessage: string;
};

export const useUpsertIntegration = ({
  successMessage,
  errorMessage,
}: UseUpsertIntegrationProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<Partial<IntegrationType>>({
    mutationFn: integrationData => updateCompanyIntegrationAPI(user.id, integrationData),
    onSuccess: () => {
      dispatch(messageActions.openMessage(MessageTypes.success, successMessage));
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [INTEGRATION_KEY],
        exact: true,
      });
    },
    onError: () => {
      dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};
