import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import {
  services as profileServices,
  constants as profileConstants,
  UpsertSmtpServerVariables,
  useGetProfile,
} from 'modules/profile';
import { MessageTypes, actions as messageActions } from 'modules/message';
import { actions as modalActions } from 'modules/modal';
import { useLocalStorage } from 'modules/hooks';
import { SHOULD_SHOW_SMTP_SERVER_CONNECTION_WARNING_MODAL_STORAGE_KEY } from 'modules/product/constants';
import { I18nEnum } from 'types';

export const useUpsertSmtpServer = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [, setShouldShowSmtpServerConnectionWarningModal] = useLocalStorage(
    SHOULD_SHOW_SMTP_SERVER_CONNECTION_WARNING_MODAL_STORAGE_KEY,
  );

  const { data: profile } = useGetProfile();

  return useAppMutation<UpsertSmtpServerVariables>({
    mutationFn: async variables => {
      if (!profile?.id) return;

      return profileServices.upsertSmtpServer(profile.id, variables.body);
    },
    onSuccess: (_data, { successMessageId }) => {
      dispatch(modalActions.closeModal());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: successMessageId || I18nEnum.ChangesHaveBeenSavedSuccessfully }),
        ),
      );

      setShouldShowSmtpServerConnectionWarningModal(true);
      queryClient.invalidateQueries({
        queryKey: [profileConstants.PROFILE_KEY],
      });
    },
    onError: error => {
      if (error.code === 400) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.error,
            intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
          ),
        );
      }
    },
  });
};
