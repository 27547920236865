import api from 'services/api';
import { CompanyEmailSetting, ApplyEmailSettingsBody, CompanyNotificationsSettings } from './types';

export const getCompanyEmailSettingsAPI = (userId: number) =>
  api.get(`user/${userId}/company/email-settings`);

export const useTheSameEmailsAPI = (userId: number, body: { emailSettingId?: number }) =>
  api.post(`user/${userId}/company/email-settings/use-the-same`, body);

export const updateOutboundEmailAPI = (userId: number, body: CompanyEmailSetting) =>
  api.post(`user/${userId}/company/email-settings/outbound`, body);

export const updateInboundEmailAPI = (userId: number, body: CompanyEmailSetting) =>
  api.post(`user/${userId}/company/email-settings/inbound`, body);

export const applyEmailSettingsAPI = (userId: number, body: ApplyEmailSettingsBody) =>
  api.post(`user/${userId}/company/email-settings/apply`, body);

export const getCompanyNotificationsSettingsAPI = (userId: number) =>
  api.get(`user/${userId}/company/notifications-settings`);

export const updateCompanyNotificationsSettingsAPI = (
  userId: number,
  body: Partial<CompanyNotificationsSettings>,
) => api.post(`user/${userId}/company/notifications-settings`, body);
