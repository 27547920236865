import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCookies } from 'utils';

import { AllPermissionsType, Roles, UserState } from 'modules/auth/types';
import { getUserFromTokenPayload, checkPermissions, isAdmin } from 'modules/auth/utils';
import { validateToken } from 'modules/global/services';
import { actions as spinnerActions } from 'modules/spinner';

interface Props {
  permissionAccess?: AllPermissionsType[];
  roleAccess?: Roles[];
  isAuthenticationRestricted?: boolean;
}

const defaultAuthState = {
  isAuthenticated: false,
  isAuthorized: false,
  isValidated: false,
  isAdmin: false,
};

const useToken = ({ permissionAccess, roleAccess }: Props) => {
  const dispatch = useDispatch();
  const [authState, setAuthState] = useState(defaultAuthState);

  const verifyToken = useCallback(async () => {
    const { token } = getCookies(document.cookie);

    if (!token) {
      setAuthState({
        isValidated: true,
        isAuthenticated: false,
        isAuthorized: false,
        isAdmin: false,
      });

      return;
    }

    const user: UserState = getUserFromTokenPayload(token);

    try {
      dispatch(spinnerActions.show({ backgroundOpacity: 0.5 }));
      await validateToken(user.id, token);

      const { hasCorrespondedPermission, hasCorrespondedRole } = checkPermissions({
        user,
        permissionAccess,
        roleAccess,
      });

      setAuthState(props => ({
        ...props,
        isAuthenticated: true,
        isAuthorized: hasCorrespondedPermission || hasCorrespondedRole,
        isAdmin: isAdmin(user),
      }));
    } finally {
      setAuthState(props => ({ ...props, isValidated: true }));
      dispatch(spinnerActions.hide());
    }
  }, [dispatch, permissionAccess, roleAccess]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  return {
    authState,
  };
};

export default useToken;
