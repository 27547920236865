import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AdminRoutes, Routes, UserRoutes } from 'types';
import { useClearProfile } from 'modules/profile/queries';
import { useTrackAuthChangeAction, AuthActionLabels } from 'modules/userAction';
import * as userSelectors from 'modules/auth/selectors';
import { clearUser } from 'modules/global/actions';
import { useBroadcastChannelConsumer } from 'modules/hooks/useBroadcastChannel';
import { ROUTER_BLOCKER_BROADCAST_CHANNEL } from 'modules/hooks/useRouterBlocker';

export const useSignOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);
  const clearProfile = useClearProfile();
  const trackAuthChangeAction = useTrackAuthChangeAction();

  const { isProviderAlive, channel } = useBroadcastChannelConsumer(
    ROUTER_BLOCKER_BROADCAST_CHANNEL,
  );

  const signOutCallback = useCallback(async () => {
    await trackAuthChangeAction(user.id, AuthActionLabels.logout);

    dispatch(clearUser());
    clearProfile();

    if (
      (Object.values(UserRoutes) as string[])
        .concat(Object.values(AdminRoutes))
        .some(route => route === location.pathname)
    ) {
      navigate(Routes.home);
    }
  }, [dispatch, navigate, clearProfile, trackAuthChangeAction, user]);

  const handleSignOut = useCallback(async () => {
    if (!isProviderAlive) {
      signOutCallback();
      return;
    }

    channel.postMessage({ action: 'signOut' });

    channel.onmessage = event => {
      if ('proceed' in event.data && event.data.proceed) {
        signOutCallback();
      }
    };
  }, [isProviderAlive]);

  return handleSignOut;
};
