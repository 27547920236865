import api from 'services/api';
import { AddContractorBody, UpdateContractorBody, AdminFiltersBody } from 'modules/admin/types';
import {
  SubscriptionMarketsUpdate,
  SubscriptionRequestType,
  SubscriptionUpdate,
} from 'modules/subscription/types';

export const getContractorsDashboardAPI = (userId: number, body?: AdminFiltersBody) =>
  api.post(`admin/${userId}/contractors`, body);

export const getContractorsDashboardFilterAPI = (userId: number, body?: AdminFiltersBody) =>
  api.post(`admin/${userId}/contractors/filter`, body);

export const getContractorAPI = (userId: number, contractorId: number) =>
  api.get(`admin/${userId}/contractor/${contractorId}`);

export const addContractorAPI = (userId: number, body: AddContractorBody) =>
  api.post(`admin/${userId}/contractor`, body);

export const updateContractorAPI = (
  userId: number,
  contractorId: number,
  body: UpdateContractorBody,
) => api.patch(`admin/${userId}/contractor/${contractorId}`, body);

export const deleteContractorAPI = (userId: number, contractorId: number) =>
  api.delete(`admin/${userId}/contractor/${contractorId}`);

export const addSubscriptionAPI = ({
  userId,
  companyId,
  data,
}: {
  userId: number;
  companyId: number;
  data: SubscriptionUpdate;
}) => api.post(`admin/${userId}/subscription/${companyId}`, data);

export const updateSubscriptionAPI = ({
  userId,
  data,
  subscriptionId,
}: {
  userId: number;
  data: SubscriptionUpdate;
  subscriptionId: number;
}) => api.patch(`admin/${userId}/update-subscription/${subscriptionId}`, data);

export const updateSubscriptionMarketsAPI = ({
  userId,
  data,
  contractorId,
}: {
  userId: number;
  data: SubscriptionMarketsUpdate;
  contractorId: number;
}) => api.post(`admin/${userId}/update-markets/${contractorId}`, data);

export const toggleSubscriptionAPI = (userId: number, companyId: number) =>
  api.post(`admin/${userId}/toggle-subscription/${companyId}`);

export const sendPasswordAPI = (userId: number, contractorId: number) =>
  api.get(`admin/${userId}/send-password/${contractorId}`);

export const resetSubscriptionStatusChangingRequestApi = (
  userId: number,
  subscriptionId: number,
  body: { requestType: SubscriptionRequestType },
) => api.post(`admin/${userId}/reset-subscription-status-changing-request/${subscriptionId}`, body);

export const getHSCompanyListAPI = (userId: number) => api.get(`admin/${userId}/hs-company-list`);

export const getHSCompanyContactAPI = (userId: number, hsCompanyId: number) =>
  api.get(`admin/${userId}/company-contact/${hsCompanyId}`);

export const getContractorToken = (adminId: number, contractorId: number) =>
  api.get(`admin/${adminId}/auth/${contractorId}`);
export const getContractorsForDropdownAPI = (userId: number, params: { search: string }) =>
  api.get(`admin/${userId}/contractors-dropdown`, {
    params,
  });
export const getRecurlyAccountsAPI = (adminId: number) =>
  api.get(`admin/${adminId}/recurly-accounts`);
export const getCompanyRecurlyAccountAPI = (adminId: number, companyId: number) =>
  api.get(`admin/${adminId}/recurly-account/${companyId}`);
