import omit from 'lodash/omit';

import { isGAFQuickMeasure } from 'utils';
import {
  DeliveryTime,
  EagleviewOrder,
  EagleviewReportWithOrderData,
  GAFQuickMeasure,
  LeadDetailsTabKeys,
  MeasurementsReportType,
  MeasurementsStructures,
  MeasureOrderStatus,
  ProductCode,
  ReportType,
  StatisticsProduct,
  StatisticsProductSettings,
  StatisticsProductWithCustom,
} from './types';

export const createEagleReportsFilter =
  (reportType: MeasurementsReportType) => (eagleviewOrders?) => {
    if (!eagleviewOrders) {
      return [];
    }

    return eagleviewOrders
      .filter(order => order.type === reportType)
      .map(order => ({
        ...order.report,
        ...omit(order, 'report'),
      })) as EagleviewReportWithOrderData[];
  };

export const generateMeasurementInitialValues = (
  order: EagleviewReportWithOrderData | GAFQuickMeasure,
) => {
  const reportType = isGAFQuickMeasure(order) ? MeasurementsReportType.GAFMeasurements : order.type;

  const initialValues = { reportType, structuresType: order.structuresType };

  if (reportType === MeasurementsReportType.GAFMeasurements) {
    const report = order as GAFQuickMeasure;

    Object.assign(initialValues, {
      productCode: report.productCode,
      instructions: report.instructions,
    });
  } else {
    const report = order as EagleviewReportWithOrderData;
    Object.assign(initialValues, {
      deliveryTime: report.deliveryType,
    });
  }

  return initialValues;
};

export const getDefaultMeasurementInitialValues = (reportType: MeasurementsReportType) => {
  const initialValues = {
    reportType,
    structuresType:
      reportType !== MeasurementsReportType.EagleViewBidPerfect
        ? MeasurementsStructures.All
        : MeasurementsStructures.MainRoofOnly,
  };

  if (reportType === MeasurementsReportType.GAFMeasurements) {
    Object.assign(initialValues, {
      productCode: ProductCode.SF,
    });
  } else {
    reportType !== MeasurementsReportType.EagleViewBidPerfect &&
      Object.assign(initialValues, {
        deliveryTime: DeliveryTime.ThreeHourDelivery,
      });
  }

  return initialValues;
};

export const getLeadsDetailsTabByReport = (report: ReportType) => {
  let tab = LeadDetailsTabKeys.Details;

  switch (true) {
    case isGAFQuickMeasure(report): {
      tab = LeadDetailsTabKeys.GAFQuickMeasure;
      break;
    }
    case (report as EagleviewOrder).type === MeasurementsReportType.EagleViewBidPerfect: {
      tab = LeadDetailsTabKeys.EagleviewBidPerfect;
      break;
    }
    case (report as EagleviewOrder).type === MeasurementsReportType.EagleViewPremiumCommercial: {
      tab = LeadDetailsTabKeys.EagleviewPremiumCommercial;
      break;
    }
    case (report as EagleviewOrder).type === MeasurementsReportType.EagleViewPremiumResidential: {
      tab = LeadDetailsTabKeys.EagleviewPremiumResidential;
      break;
    }
  }

  return tab;
};

export const isCompletedReport = (report?: ReportType) =>
  !!report && report.status === MeasureOrderStatus.completed;

export const combineLeadProductsWithProductsSettings = (
  products: StatisticsProduct[],
  productsSettings: StatisticsProductSettings[],
): StatisticsProductWithCustom[] =>
  products.map(product => {
    const productSetting = productsSettings.find(
      setting => setting.id && setting.id === product.id,
    );
    return { ...product, customProduct: productSetting?.customProduct };
  });
