import { useSelector } from 'react-redux';

import { selectUser } from 'modules/auth/selectors';
import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { IntegrationType } from '../types';
import { INTEGRATION_KEY } from '../constants';
import { services } from '..';

export const useGetIntegration = () => {
  const user = useSelector(selectUser);

  return useAppQuery<IntegrationType>(
    [INTEGRATION_KEY],
    () => services.getCompanyIntegrationAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};
