import { useMemo } from 'react';
import { MenuItem as MenuItemType, NotificationTypes, NotificationType } from '../types';

const notificationSeverity = {
  [NotificationTypes.error]: 0,
  [NotificationTypes.warning]: 1,
};

const useMostSevereNotificationType = (items: MenuItemType[]): NotificationType | undefined => {
  const mostSevereNotificationType = useMemo(() => {
    let result;

    items.forEach(({ notification }) => {
      if (
        (notification && !result) ||
        (notification && notificationSeverity[notification.type] < notificationSeverity[result])
      ) {
        result = notification.type;
      }
    });

    return result;
  }, [items]);

  return mostSevereNotificationType;
};

export default useMostSevereNotificationType;
