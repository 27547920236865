import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { RawIntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { intl } from 'intl';
import { theme } from 'global-constants';
import { queryClient } from 'modules/reactQuery';

const Provider = ({ children, store }) => (
  <ReduxProvider store={store}>
    <RawIntlProvider value={intl}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </ThemeProvider>
    </RawIntlProvider>
  </ReduxProvider>
);

export default Provider;
