import { useDispatch, useSelector } from 'react-redux';

import { I18nEnum, ExportType } from 'types';
import { useAppMutation } from 'modules/reactQuery';
import { selectors as authSelectors } from 'modules/auth';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { services as financingServices } from 'modules/financing';
import { downloadBlobFile } from 'utils';

export const useResendInvitation = () => {
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.selectUser);

  return useAppMutation<number>({
    mutationFn: loanId => financingServices.resendInvitationApi(user.id, loanId),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(MessageTypes.success, I18nEnum.EmailHasBeenSentSuccessfully),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(MessageTypes.error, I18nEnum.UnfortunatelyTheEmailHasNotBeen),
      );
    },
  });
};

export const useExportMerchantStatementDetails = () => {
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.selectUser);

  return useAppMutation<
    {
      statementId: number;
      exportType: ExportType;
      statementNumber: string;
      successCallback?: () => void;
    },
    Blob
  >({
    mutationFn: ({ statementId, exportType }) =>
      financingServices.exportMerchantStatementDetails(user.id, statementId, exportType),
    onSuccess: (blob, { exportType, statementNumber, successCallback }) => {
      downloadBlobFile(blob, `merchant_statement_${statementNumber}`, exportType);
      successCallback && successCallback();
      dispatch(
        messageActions.openMessage(MessageTypes.success, I18nEnum.FileHasBeenExportedSuccessfully),
      );
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          I18nEnum.UnfortunatelyTheFileHasNotBeenExported,
        ),
      );
    },
  });
};
