import { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Routes, UserRoutes, AdminRoutes } from 'types';
import { AllPermissionsType, Roles } from 'modules/auth/types';
import useToken from './useToken';

interface Props {
  permissionAccess?: AllPermissionsType[];
  roleAccess?: Roles[];
  isAuthenticationRestricted?: boolean;
}

const ProtectedRoute = ({ permissionAccess, roleAccess, isAuthenticationRestricted }: Props) => {
  const location = useLocation();

  const { authState } = useToken({ permissionAccess, roleAccess });

  const defaultPage = useMemo(
    () => (authState.isAdmin ? AdminRoutes.adminDashboard : UserRoutes.dashboard),
    [authState.isAdmin],
  );

  if (!authState.isValidated) {
    return null;
  }

  if (isAuthenticationRestricted && authState.isAuthenticated) {
    return <Navigate to={defaultPage} />;
  }

  if (!authState.isAuthenticated) {
    return !Object.values(Routes).includes(location.pathname as Routes) ? (
      <Navigate to={Routes.signin} />
    ) : (
      <Outlet />
    );
  }

  return !authState.isAuthorized && location.pathname !== defaultPage ? (
    <Navigate to={defaultPage} />
  ) : (
    <Outlet />
  );
};

export default ProtectedRoute;
