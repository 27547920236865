import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { selectors as userSelectors } from 'modules/auth';
import { updateCompanyJobNimbusIntegrationAPI } from '../services';
import { JobNimbusIntegration } from '../types';
import { JOBNIMBUS_CONNECTION_KEY } from '../constants';

type UseUpsertIntegrationProps = {
  successMessage: string;
  errorMessage: string;
};

export const useUpsertJobNimbusIntegration = ({
  successMessage,
  errorMessage,
}: UseUpsertIntegrationProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: Partial<JobNimbusIntegration>; callback: () => void }>({
    mutationFn: ({ body }) => updateCompanyJobNimbusIntegrationAPI(user.id, body),
    onSuccess: (_, { callback }) => {
      dispatch(messageActions.openMessage(MessageTypes.success, successMessage));

      queryClient.invalidateQueries({
        queryKey: [JOBNIMBUS_CONNECTION_KEY],
        exact: true,
      });

      callback();
    },
    onError: () => {
      dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};
