import { useMemo } from 'react';

import { useGetCompanyEmailSettings } from 'modules/emailsSettings/queries';
import { allMarketsSettingKey } from 'modules/emailsSettings/constants';

const useIsAllMarketsEmailsConnected = (): {
  isAllMarketsEmailsConnected: boolean;
  isSomeEmailDisconnected: boolean;
} => {
  const { data: emailSettingsResponse } = useGetCompanyEmailSettings();

  const emailsConnectionStatus = useMemo(() => {
    let isAllMarketsEmailsConnected = true;
    let isSomeEmailDisconnected = false;
    if (!emailSettingsResponse) {
      return {
        isAllMarketsEmailsConnected,
        isSomeEmailDisconnected,
      };
    }

    Object.keys(emailSettingsResponse.emailSettings).forEach(key => {
      if (key === allMarketsSettingKey) {
        return;
      }
      const emailSetting = emailSettingsResponse.emailSettings[key];
      const smtpServer = emailSetting.outboundEmailSmtpServer;
      if (!smtpServer) {
        return;
      }
      if (!smtpServer.host) {
        isAllMarketsEmailsConnected = false;
      } else if (!smtpServer.isConnected) {
        isSomeEmailDisconnected = true;
      }
    });

    return {
      isAllMarketsEmailsConnected,
      isSomeEmailDisconnected,
    };
  }, [emailSettingsResponse]);

  return emailsConnectionStatus;
};

export default useIsAllMarketsEmailsConnected;
