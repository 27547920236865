import { useSelector } from 'react-redux';

import { selectUser } from 'modules/auth/selectors';
import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { JobNimbusIntegrationType } from '../types';
import { JOBNIMBUS_CONNECTION_KEY } from '../constants';
import { services } from '..';

export const useGetJobNimbusIntegration = () => {
  const user = useSelector(selectUser);

  const { data: jobNimbusIntegration, isFetching } = useAppQuery<JobNimbusIntegrationType>(
    [JOBNIMBUS_CONNECTION_KEY],
    () => services.getCompanyJobNimbusIntegrationAPI(user.id),
    {
      enabled: !!user.id,
    },
  );

  return !isFetching ? jobNimbusIntegration : undefined;
};
