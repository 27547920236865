import { useEffect, useRef } from 'react';

const useOnClickOutside = (handler: (e: any) => any, ignoreId?: string) => {
  const ref = useRef<any>(null);

  useEffect(
    () => {
      const listener = (event: Event) => {
        let ignore = false;

        if (ignoreId) {
          const element = document.getElementById(ignoreId);
          ignore = (event.target as Element)?.id === ignoreId;

          if (event.target && element) {
            ignore = ignore || element.contains(event.target as Node);
          }
        }

        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          getComputedStyle(ref.current).display === 'none' ||
          ignore
        ) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    // To optimize wrap handler in useCallback before passing it into this hook
    [ref, handler],
  );

  return ref;
};

export { useOnClickOutside };
