import { lazy } from 'react';

import { AdminRoutes, Routes, UserRoutes } from 'types';

import { AllPermissions } from 'modules/auth/types';

const SignInPage = lazy(
  () => import(/* webpackChunkName: "SignInPage" */ 'modules/auth/components/SignIn'),
);
const ForgotPasswordPage = lazy(
  () =>
    import(/* webpackChunkName: "ForgotPasswordPage" */ 'modules/auth/components/ForgotPassword'),
);
const ResetPasswordPage = lazy(
  () => import(/* webpackChunkName: "ResetPasswordPage" */ 'modules/auth/components/ResetPassword'),
);
const ProfilePage = lazy(
  () => import(/* webpackChunkName: "ProfilePage" */ 'modules/profile/components/MyProfile'),
);
const QuickQuotePage = lazy(
  () => import(/* webpackChunkName: "QuickQuotePage" */ 'modules/quickQuote/components/QuickQuote'),
);
const BillingPage = lazy(
  () => import(/* webpackChunkName: "BillingPage" */ 'modules/billing/components/Billing'),
);
const CompanyProfilePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyProfilePage" */ 'modules/company/components/CompanyProfile'
    ),
);
const StatisticsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StatisticsPage" */ 'modules/dashboard/components/ContactsReporting'
    ),
);
const AdminDashboardPage = lazy(
  () =>
    import(/* webpackChunkName: "AdminDashboardPage" */ 'modules/admin/components/AdminDashboard'),
);
const WidgetSettingsPage = lazy(
  () => import(/* webpackChunkName: "WidgetSettingsPage" */ 'modules/widget/components/Settings'),
);
const ProductsPage = lazy(
  () => import(/* webpackChunkName: "ProductsPage" */ 'modules/product/components/Products'),
);
const DeveloperPage = lazy(
  () =>
    import(/* webpackChunkName: "DeveloperPage" */ 'modules/developer/components/DeveloperInfo'),
);
const FinancingPage = lazy(
  () => import(/* webpackChunkName: "FinancingPage" */ 'modules/financing/components/Financing'),
);

const StatementDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StatementDetailsPage" */ 'modules/financing/components/ContractorLoanPro/Statements/StatementDetails'
    ),
);

const AnalyticsReports = lazy(
  () =>
    import(
      /* webpackChunkName: "AnalyticsReports" */ 'modules/analytics/components/AnalyticsReports'
    ),
);

const RepQuotesPage = lazy(
  () => import(/* webpackChunkName: "RepQuotesPage" */ 'modules/repQuotes/components/RepQuotes'),
);

const ApproveEmail = lazy(
  () => import(/* webpackChunkName: "SignInPage" */ 'modules/auth/components/ApproveEmail'),
);

const OAuthHandler = lazy(
  () => import(/* webpackChunkName: "OAuthHandler" */ 'modules/auth/components/OAuthHandler'),
);

const MarketsSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "MarketsSettings" */ 'modules/markets/settings/components/MarketsSettings'
    ),
);

const Preview = lazy(() => import(/* webpackChunkName: "Preview" */ 'modules/Preview'));

const Orders = lazy(
  () => import(/* webpackChunkName: "Orders" */ 'modules/orders/components/Orders'),
);

const LoanInvitation = lazy(
  () => import(/* webpackChunkName: "LoanInvitation" */ 'modules/widget/components/LoanInvitation'),
);

const PublicSendLoanInvitationModalPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PublicSendLoanInvitationModal" */ 'modules/clpButtonSettings/modals/PublicSendLoanInvitationModal'
    ),
);

export const publicRoutes = [
  {
    path: Routes.quickQuote,
    Component: QuickQuotePage,
  },
  {
    path: Routes.approveEmail,
    Component: ApproveEmail,
  },
  {
    path: Routes.googleOauthCallback,
    Component: OAuthHandler,
  },
  {
    path: Routes.outlookOauthCallback,
    Component: OAuthHandler,
  },
  {
    path: Routes.eagleviewOauthCallback,
    Component: OAuthHandler,
  },
  {
    path: Routes.loanInvitation,
    Component: LoanInvitation,
  },
  {
    path: Routes.loanInvitationModal,
    Component: PublicSendLoanInvitationModalPage,
  },
];

export const previewRoutes = [
  {
    path: Routes.preview,
    Component: Preview,
  },
];

const routes = [
  {
    path: Routes.home,
    Component: SignInPage,
    isAuthenticationRestricted: true,
  },
  {
    path: Routes.signin,
    Component: SignInPage,
    isAuthenticationRestricted: true,
  },
  {
    path: Routes.forgotPassword,
    Component: ForgotPasswordPage,
    isAuthenticationRestricted: true,
  },
  {
    path: Routes.resetPassword,
    Component: ResetPasswordPage,
    isAuthenticationRestricted: true,
  },
  {
    path: UserRoutes.dashboard,
    Component: StatisticsPage,
    permissionAccess: [AllPermissions.customerStatistics],
  },
  {
    path: UserRoutes.analyticsReports,
    Component: AnalyticsReports,
    permissionAccess: [AllPermissions.analyticsReports],
  },
  {
    path: UserRoutes.toolSettings,
    Component: WidgetSettingsPage,
    permissionAccess: [AllPermissions.widgetSetting],
  },
  {
    path: UserRoutes.developerInfo,
    Component: DeveloperPage,
    permissionAccess: [AllPermissions.developerInfo],
  },
  {
    path: UserRoutes.financingSettings,
    Component: FinancingPage,
    permissionAccess: [AllPermissions.financingSettings],
  },
  {
    path: UserRoutes.statementDetails,
    Component: StatementDetailsPage,
    permissionAccess: [AllPermissions.financingSettings],
  },
  {
    path: UserRoutes.billing,
    Component: BillingPage,
    permissionAccess: [AllPermissions.billing],
  },
  {
    path: UserRoutes.profile,
    Component: ProfilePage,
    permissionAccess: [AllPermissions.userProfile, AllPermissions.adminProfile],
    roleAccess: [],
  },
  {
    path: UserRoutes.companyProfile,
    Component: CompanyProfilePage,
    permissionAccess: [AllPermissions.companyProfile],
  },
  {
    path: UserRoutes.products,
    Component: ProductsPage,
    permissionAccess: [AllPermissions.productsSelection],
  },
  {
    path: UserRoutes.repQuotes,
    Component: RepQuotesPage,
    permissionAccess: [AllPermissions.repQuotes],
  },
  {
    path: AdminRoutes.adminDashboard,
    Component: AdminDashboardPage,
    permissionAccess: [AllPermissions.manageContractors],
  },
  {
    path: UserRoutes.marketsSettings,
    Component: MarketsSettings,
    permissionAccess: [AllPermissions.marketsSettings],
  },
  {
    path: UserRoutes.orders,
    Component: Orders,
    permissionAccess: [AllPermissions.orders, AllPermissions.ordersAccountsManagement],
  },
];

export default routes;
